import React from 'react';

import getEndText from '@functions/getEndText.ts';
import PayGroupT from '@global/models/PayGroup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

import Amount from './paysGroups/Amount.tsx';
import DateStr from './paysGroups/Date.tsx';
import Status from './paysGroups/Status.tsx';

const renderPaysGroups: RenderColsT<'paysGroups'> = function ({ name: colName, item }) {
    const { otherData } = this.props;
    const payGroup = item as PayGroupT;
    const isAdmin = this.checkAdmin();
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text _mediumWeight">{payGroup.name}</div>
            </>
        );
    }

    if (colName === 'counter') {
        content = (
            <>
                <div className="v2table__text">{payGroup.counter}</div>
                {isAdmin ? (
                    <>
                        {' '}
                        {getEndText(payGroup.counter, ['акт', 'акта', 'актов'])} на сумму{' '}
                        <Amount payGroup={payGroup} /> от&nbsp;
                        <DateStr payGroup={payGroup} />
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }

    if (colName === 'amount') {
        content = (
            <>
                <Amount payGroup={payGroup} />
            </>
        );
    }

    if (colName === 'date') {
        content = (
            <>
                <DateStr payGroup={payGroup} />
            </>
        );
    }

    if (colName === 'signStatus') {
        className = '_full';
        content = (
            <>
                <Status payGroup={payGroup} type="sign" />
            </>
        );
    }

    if (colName === 'payStatus') {
        const getMoreActions = otherData?.getMoreActions as (data: {
            payGroup: PayGroupT;
        }) => CursorActionsItemT[];
        const actions = getMoreActions({ payGroup });

        className = '_full _withMore';

        content = (
            <>
                <Status payGroup={payGroup} />
                <More
                    id={`payGroup-${payGroup._id}`}
                    items={actions}
                    stopPropagation={true}
                />
            </>
        );
    }

    return { content, className };
};

export default renderPaysGroups;
