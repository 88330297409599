import React from 'react';

import Link from '@components/link/Link.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import ButtonI from './types.ts';

class Button extends React.Component<ButtonI['props']> implements ButtonI {
    constructor(props: ButtonI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            children,
            pageName,
            onClick,
            disabled,
            ids,
            className = '',
            loading,
            callback,
            label,
        } = this.props;
        let ButtonTag: React.ElementType = pageName ? Link : 'button';

        let buttonProps: Record<any, unknown> = pageName
            ? {
                  pageName,
                  ids,
                  tag: 'button',
                  callback,
              }
            : { ...(!disabled && !loading ? { onClick } : {}) };

        if (label) {
            ButtonTag = 'label';
            buttonProps = {};
        }

        return (
            <>
                <ButtonTag
                    className={`v2button _ROW _CLICK _FOCUS ${disabled ? '_disabled' : ''} ${className}`}
                    {...buttonProps}
                    disabled={!!disabled}
                >
                    <LoaderBlock className="v2button__loader" isShow={!!loading} />
                    {children}
                </ButtonTag>
            </>
        );
    }
}

export default Button;
