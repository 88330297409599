import React from 'react';

import ListScroll from '@components/listScroll/ListScroll.tsx';

import I from '../types.ts';

const renderList: I['renderList'] = function () {
    const { callback } = this.props;
    const emodziList = this.list.filter(this.filterItem.bind(this));

    return (
        <div className="v2emodziList__items _ROW">
            <ListScroll
                isInit={true}
                getParent={() => this.parent.current?.querySelector('.v2emodziList__inner') || null}
                callback={this.getMoreItems.bind(this)}
                startCounter={this.stepCounter}
                stepCounter={this.stepCounter}
                maxCounter={this.list.length}
                lengthCurrent={emodziList.length}
                isLimit={this.list.length === emodziList.length}
                isDisabledScroll={false}
                callbackDuration={0}
                hideCallback={this.hideCallback.bind(this)}
            >
                <>
                    {emodziList.map((item, key) => (
                        <div className="v2emodziList__item _COL" key={key}>
                            <div
                                className="v2emodziList__itemInner _COL _CLICK"
                                onClick={() => {
                                    if (callback) {
                                        callback(item);
                                    }
                                }}
                            >
                                {item}
                            </div>
                        </div>
                    ))}
                </>
            </ListScroll>
        </div>
    );
};

export default renderList;
