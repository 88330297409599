import React from 'react';

import clickOutHandler from './methods/clickOutHandler.ts';
import filterItem from './methods/filterItem.ts';
import getMoreItems from './methods/getMoreItems.ts';
import hideCallback from './methods/hideCallback.ts';
import init from './methods/init.ts';

import EmodziListI from './types.ts';

import renderList from './renders/renderList.tsx';
import list from './static/list.ts';

class EmodziList
    extends React.Component<EmodziListI['props'], EmodziListI['state']>
    implements EmodziListI
{
    parent: EmodziListI['parent'];

    constructor(props: EmodziListI['props']) {
        super(props);
        this.state = {
            counterScroll: this.stepCounter,
        };

        this.clickOutHandler = this.clickOutHandler.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 60;
    list = list;

    renderList = renderList;

    getMoreItems = getMoreItems;
    filterItem = filterItem;
    hideCallback = hideCallback;
    clickOutHandler = clickOutHandler;

    init = init;

    componentDidMount(): void {
        this.init();

        setTimeout(() => {
            document.addEventListener('click', this.clickOutHandler);
        }, 10);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.clickOutHandler);
    }

    render() {
        return (
            <div ref={this.parent} className="v2emodziList">
                <div className="v2emodziList__inner _NOSCROLL">{this.renderList()}</div>
            </div>
        );
    }
}

export default EmodziList;
