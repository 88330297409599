const chatExecutorDocs = {
    colsOrder: ['name', 'preview'],
    cols: {
        name: {
            content: 'Название документа',
            width: 70,
        },
        preview: {
            content: 'Превью',
            width: 30,
        },
    },
    deletesCols: [],
    emptyInfo: {},
} as const;

export default chatExecutorDocs;
