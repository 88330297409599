import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getHeaders from '../../../functions/getHeaders';
import Icon from '../../icon/Icon.tsx';

class PayCancel extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.save = this.save.bind(this);
        this.hide = this.hide.bind(this);
    }

    reasons = {
        REFUND: {
            content: 'Возврат средств',
        },
        REGISTRATION_MISTAKE: {
            content: 'Чек сформирован ошибочно',
        },
    };

    hide() {
        super.hide();

        handlerPopup({ name: 'appPayCancelPopup', isShow: false });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async save() {
        const { currentReason } = this.state;
        const { appPayCancelPopup } = this.props;
        const { payId, prevHide } = appPayCancelPopup;

        if (currentReason) {
            await this.handlerLoading('save');

            axios
                .patch(
                    `${process.env.REACT_APP_API}/pays`,
                    { action: 'cancel-pay', id: payId, reason: currentReason },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        if (success) {
                            this.hide();

                            if (typeof prevHide === 'function') {
                                prevHide();
                            }
                        }

                        this.handlerLoading(null);
                    },
                    () => null,
                );
        }
    }

    render() {
        const { currentReason, loadingKey } = this.state;
        const { appPayCancelPopup } = this.props;
        const { payNumber } = appPayCancelPopup;

        return (
            <div ref={this.parent} className="appPopup _col">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__title">
                                Аннулировать чек акта №{payNumber}
                            </div>
                            <div className="appPopup__description">
                                Выберите причину отмены чека, после чего нажмите на кнопку
                                «Аннулировать&nbsp;чек»
                            </div>
                            <div className="appPopup__checkboxes">
                                {Object.keys(this.reasons).map((key) => (
                                    <label className="appPopup__checkbox _click" key={key}>
                                        <input
                                            type="checkbox"
                                            className="appPopup__checkboxInput"
                                            checked={currentReason === key}
                                            onChange={() => {
                                                this.setState({
                                                    currentReason:
                                                        currentReason === key ? null : key,
                                                });
                                            }}
                                        />
                                        <div className="appPopup__checkboxInner _row">
                                            <div className="appPopup__checkboxPoint _col">
                                                <i className="appPopup__checkboxPointIcon">
                                                    <Icon name="done" />
                                                </i>
                                            </div>
                                            {this.reasons[key].content}
                                        </div>
                                    </label>
                                ))}
                            </div>

                            <div className="appPopup__button">
                                <Button onClick={this.save} showLoader={loadingKey === 'save'}>
                                    Аннулировать чек
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        appPayCancelPopup: state.appPayCancelPopup,
    };
}

export default connect(mapStateToProps)(PayCancel);

PayCancel.propTypes = {
    windowHeight: PropTypes.number,
    appPayCancelPopup: PropTypes.object,
};
