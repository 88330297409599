import changePage from '@functions/changePage';
import handlerPopup from '@functions/handlerPopup';
import UserT from '@global/models/User';
import { store } from '@redux/redux';

import getCurrentCorporation from './getCurrentCorporation';

type ParamsT = {
    isShow?: boolean;
    callback?: () => void;
};

export default function verificationPopupHandler({ isShow, callback }: ParamsT): void {
    const user = store.getState().user as UserT;

    if (user) {
        const dateKey = `${new Date().getUTCDate()}-${new Date().getUTCMonth()}-${new Date().getUTCFullYear()}`;
        const corporation = getCurrentCorporation({ user }) as UserT['corporationsInfo'][number];

        if (
            corporation &&
            corporation.role === 'holder' &&
            ['reject', 'wait'].includes(corporation.verificationStatus!)
        ) {
            const storageKey = `verification-${corporation._id}`;
            const storageDate = localStorage.getItem(storageKey);

            const resultShow = isShow ?? true;

            if (resultShow === true && storageDate === dateKey) {
                return;
            }

            handlerPopup('alertPopup', {
                isShow: resultShow,
                type: 'verification',
                callback: () => {
                    changePage<'crm'>({ pageName: 'settings-docs' });

                    if (typeof callback === 'function') {
                        callback();
                    }

                    localStorage.setItem(storageKey, dateKey);
                },
                closeCallback: () => {
                    localStorage.setItem(storageKey, dateKey);
                },
            });
        }
    }
}
