import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Link from '@components/link/Link';

import Button from '../../components/Button.jsx';

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const textVersion = process.env.REACT_APP_APP_VERSION?.split('').join('.');

        return (
            <div className="start">
                <div className="start__inner _col">
                    <div className="start__content _col">
                        <img
                            src={require('../../img/emodzi/victory-hand.png')}
                            alt=""
                            className="start__icon"
                        />
                        <div className="start__title">
                            Добро пожаловать
                            <br />в CRM LIVECARGO!
                        </div>
                        <p className="start__description">
                            Цифровизация вашего бизнеса:
                            <br />
                            от найма — до выплат
                        </p>
                        <Link className="start__button" href="auth">
                            <Button icon={{ type: 'end', name: 'arrow-next-2' }}>
                                Начать работу
                            </Button>
                        </Link>
                    </div>
                    <p className="start__date">Версия {textVersion}</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
    };
}

export default connect(mapStateToProps)(Start);

Start.propTypes = {
    theme: PropTypes.string,
};
