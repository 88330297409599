import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { checkValidate, regs as inputRegs } from '../functions/inputValidate';
import getEndText from '../functions/getEndText';
import checkValueOfEmpty from '../functions/checkValueOfEmpty';
import getFloatStr from '../functions/getFloatStr';

import InputMask from './InputMask.jsx';
import Icon from './icon/Icon.tsx';

import Animate from './Animate.jsx';
import InputRunning from './InputRunning.jsx';
import { dispatcher } from '../redux/redux';

const infoFields = require('../infos/fields.json');

class Field extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
            isShowPassword: false,
            isShowList: false,
        };

        this.handlerInput = this.handlerInput.bind(this);
        this.handlerPassword = this.handlerPassword.bind(this);
        this.getProp = this.getProp.bind(this);
        this.getContentOfValueSupport = this.getContentOfValueSupport.bind(this);
        this.handlerMissClickList = this.handlerMissClickList.bind(this);

        this.parent = React.createRef();
    }

    getProp(key) {
        const { keyName, type } = this.props;
        const currentInfo = infoFields[keyName];

        let prop = this.props[key] ?? currentInfo?.[key];

        if (key === 'support') {
            prop = checkValueOfEmpty(this.props[key], true)
                ? this.props[key]
                : currentInfo?.supports?.[type || 'default'] ||
                  currentInfo?.supports?.default ||
                  '';
        }

        return prop;
    }

    getContentOfValueSupport() {
        const { value } = this.props;
        const valueSupport = this.getProp('valueSupport');
        let support = '';

        if (valueSupport) {
            const { content, endOfContent } = valueSupport;

            if (endOfContent) {
                support = `${content}${getEndText(+value || 0, endOfContent)}`;
            } else {
                support = content;
            }
        }

        return support;
    }

    getMax({ value }) {
        const reg = this.getProp('reg');
        let maxLen = this.getProp('maxLen');

        switch (reg) {
            case 'numberWithSign':
                if (['-', '+'].indexOf(value[0]) !== -1) {
                    maxLen += 1;
                }
                break;
            default:
                break;
        }

        return maxLen;
    }

    changeValue({ value: valueRes }) {
        const reg = this.getProp('reg');
        const max = this.getProp('max');
        const isUpper = this.getProp('isUpper');

        let value = valueRes;

        switch (reg) {
            case 'numberWithSign':
                if (value[0] && ['-', '+'].indexOf(value[0]) === -1) {
                    value = `+${value}`;
                }
                break;
            default:
                break;
        }

        if (isUpper) {
            value = value.toUpperCase();
        }

        if (max && getFloatStr(value) > max) {
            value = max;
        }

        return value;
    }

    handlerInput({ action, value }) {
        const { name, callback } = this.props;
        const reg = this.getProp('reg');
        const isMask = this.getProp('isMask');
        const valueSupport = this.getProp('valueSupport');
        let { value: valueProps } = this.props;

        let clearValue = value || ``;

        if (value && action === 'change') {
            this.handlerShowList(false);
        }

        if (valueSupport) {
            const { position } = valueSupport;
            const contentOfValueSupport = this.getContentOfValueSupport();

            if (position === 'start') {
                clearValue = clearValue.slice(contentOfValueSupport.length, clearValue.length);
            }
            if (position === 'end') {
                const pos =
                    clearValue.length - contentOfValueSupport.length > 0
                        ? clearValue.length - contentOfValueSupport.length
                        : 0;

                clearValue = clearValue.slice(0, pos);
            }
        }

        if (valueProps === null) {
            valueProps = '';
        }

        if (!isMask && reg && inputRegs[reg]) {
            clearValue = clearValue.replace(inputRegs[reg], '');
        }

        const maxLen = this.getMax({ value: clearValue });

        if (maxLen && typeof maxLen === 'number' && clearValue.length > maxLen) {
            clearValue = clearValue.slice(0, maxLen);
        }

        clearValue = this.changeValue({ value: clearValue });

        clearValue = this.toFloatFormat(clearValue);

        return new Promise((resolve) => {
            switch (action) {
                case 'focus':
                    dispatcher({ type: 'isInputFocus', data: true });

                    this.setState(
                        (state) => {
                            const newState = { ...state };

                            newState.isFocus = true;

                            return newState;
                        },
                        () => {
                            if (callback && typeof callback === 'function') {
                                callback({ action: 'focus', name });
                            }
                            if (valueSupport) {
                                const { position } = valueSupport;

                                if (position === 'end') {
                                    this.parent.current.querySelector('.field__input').focus();
                                    this.parent.current
                                        .querySelector('.field__input')
                                        .setSelectionRange(
                                            valueProps?.length || 0,
                                            valueProps?.length || 0,
                                        );
                                }
                            }
                        },
                    );

                    break;
                case 'blur':
                    dispatcher({ type: 'isInputFocus', data: false });

                    if (callback && typeof callback === 'function') {
                        callback({ action: 'blur', name });
                    }
                    this.setState({ isFocus: false });
                    break;
                case 'change':
                    if (this.parent.current && callback && typeof callback === 'function') {
                        const input = this.parent.current.querySelector('.field__input');

                        const cursorPosition = input.selectionStart;

                        const final = () => {
                            this.parent.current
                                .querySelector('.field__input')
                                .setSelectionRange(cursorPosition, cursorPosition);

                            resolve();
                        };

                        try {
                            callback({ action, name, value: clearValue.toString() }).then(() => {
                                final();
                            });
                        } catch (error) {
                            final();
                        }
                    }
                    break;
                default:
                    break;
            }
        });
    }

    handlerPassword() {
        this.setState((state) => {
            const newState = { ...state };

            newState.isShowPassword = !newState.isShowPassword;

            return newState;
        });
    }

    checkComplete() {
        const { keyName, value } = this.props;

        return value && checkValidate({ value, type: keyName });
    }

    checkChangeComplete() {
        const { setStateComplete } = this.props;

        if (
            setStateComplete &&
            typeof setStateComplete === 'function' &&
            this.isComplete !== this.checkComplete()
        ) {
            this.isComplete = this.checkComplete();
            setStateComplete(this.isComplete);
        }
    }

    toFloatFormat(value) {
        const reg = this.getProp('reg');

        if (reg === 'numberFloat' && value) {
            const valueArr = value.toString().split(',');

            if (valueArr.length > 1) {
                const firstItem = valueArr.shift();
                const lastItems = valueArr.join('');

                const newValue = `${firstItem},${
                    lastItems.length > 2 ? lastItems.slice(0, 2) : lastItems
                }`;

                return newValue;
            }
        }

        if (reg === 'percent' && value) {
            if (+value > 100) {
                return '100';
            }

            const valueArr = value.toString().split('.');

            if (valueArr.length > 1) {
                const firstItem = valueArr.shift();
                const lastItems = valueArr.join('');

                const newValue = `${firstItem}.${
                    lastItems.length > 2 ? lastItems.slice(0, 2) : lastItems
                }`;

                return newValue;
            }
        }

        return value;
    }

    handlerShowList(isShowList) {
        this.setState({ isShowList });
    }

    handlerMissClickList({ target }) {
        const { isShowList } = this.state;
        const list = this.parent.current.querySelector('.field__list');

        if (list && isShowList && target !== list && !list.contains(target)) {
            this.handlerShowList(false);
        }
    }

    getValueClass() {
        return 'field__value _row';
    }

    componentDidMount() {
        const { list } = this.props;
        this.checkChangeComplete();

        if (list) {
            document.addEventListener('mouseup', this.handlerMissClickList);
        }
    }

    componentDidUpdate() {
        this.checkChangeComplete();
    }

    componentWillUnmount() {
        const { list } = this.props;

        if (list) {
            document.removeEventListener('mouseup', this.handlerMissClickList);
        }
    }

    render() {
        const { isFocus, isShowPassword, isShowList, isPressShift } = this.state;
        const {
            name,
            error,
            className,
            isDisabled,
            group,
            iconEdit,
            isShowTemplate,
            setStateComplete,
            list,
            callbackList,
            isRun,
            min,
            max,
            isEditmode,
            style,
            styleText,
            stopPropagation,
        } = this.props;
        let { value } = this.props;
        const id = this.props.id || `input-${name}-${group}`;
        const support = this.getProp('support');
        const charDel = this.getProp('charDel');
        const charEmpty = this.getProp('charEmpty');
        const template = this.getProp('template');
        const isPassword = this.getProp('isPassword');
        const validate = this.getProp('validate');
        const isMask = this.getProp('isMask');
        const tag = this.getProp('tag');
        const valueSupport = this.getProp('valueSupport');
        const reg = this.getProp('reg');

        if (reg === 'numberFloat' && value) {
            value = this.toFloatFormat(value);
        }

        if ((isFocus || checkValueOfEmpty(value)) && valueSupport) {
            const { position } = valueSupport;
            const contentOfValueSupport = this.getContentOfValueSupport();

            if (position === 'start') {
                value = `${contentOfValueSupport}${checkValueOfEmpty(value) ? value : ''}`;
            }
            if (position === 'end') {
                value = `${checkValueOfEmpty(value) ? value : ''}${contentOfValueSupport}`;
            }
        }

        const propsLabel = isDisabled ? {} : { htmlFor: id };

        return (
            <div
                ref={this.parent}
                className={`field ${
                    checkValueOfEmpty(value) || (!isDisabled && isFocus) ? '_notEmpty' : ''
                } ${!isDisabled && isFocus ? '_focus' : ''} _${tag} ${error ? '_error' : ''} ${
                    className || ''
                } ${iconEdit && !isPassword ? '_editIcon' : ''} ${
                    isPassword || iconEdit ? '_withIcon' : ''
                } ${isDisabled !== undefined && !isDisabled ? '_isEdit' : ''} ${
                    checkValueOfEmpty(setStateComplete) ? '_checkComplete' : ''
                } ${
                    checkValueOfEmpty(setStateComplete) && this.checkComplete() ? '_complete' : ''
                } ${isDisabled ? '_readOnly' : ''} ${isShowList ? '_showList' : ''} ${
                    isEditmode ? '_editmode' : ''
                }`}
                onClick={(e) => {
                    if (stopPropagation) {
                        e.stopPropagation();
                    }

                    this.handlerShowList(true);
                }}
                onKeyDown={(e) => {
                    if (tag === 'area') {
                        if (e.keyCode === 16) {
                            this.setState({ isPressShift: true });
                        }
                        if (e.keyCode === 13) {
                            if (!isPressShift) {
                                e.preventDefault();
                                this.parent.current.querySelector('.field__input').blur();
                            } else {
                                e.stopPropagation();
                            }
                        }
                    } else if (e.keyCode === 13) {
                        this.parent.current.querySelector('.field__input').blur();
                    }
                }}
                onKeyUp={(e) => {
                    if (tag === 'area') {
                        if (e.keyCode === 16) {
                            this.setState({ isPressShift: false });
                        }
                    }
                }}
                style={style}
            >
                {tag === 'area' ? (
                    <>
                        <textarea
                            type={isPassword ? (isShowPassword && 'text') || 'password' : 'text'}
                            className={`field__input ${this.getValueClass()}`}
                            data-name={name}
                            id={id}
                            value={checkValueOfEmpty(value) ? value : ''}
                            onChange={(e) => {
                                this.handlerInput({ action: 'change', value: e.target.value });
                            }}
                            onFocus={() => {
                                this.handlerInput({ action: 'focus' });
                            }}
                            onBlur={() => this.handlerInput({ action: 'blur' })}
                            autoComplete="off"
                            readOnly={isDisabled}
                            tabIndex={isDisabled && -1}
                            style={styleText}
                        />
                    </>
                ) : isMask ? (
                    <InputMask
                        className={`field__input ${this.getValueClass()}`}
                        name={name}
                        id={id}
                        value={checkValueOfEmpty(value) ? value : ''}
                        template={template}
                        charDel={charDel}
                        charEmpty={charEmpty}
                        validate={validate}
                        callback={this.handlerInput}
                        isDisabled={isDisabled}
                        isShowTemplate={isShowTemplate}
                        reg={reg}
                        min={min}
                        max={max}
                        style={styleText}
                    />
                ) : (
                    <InputRunning
                        type={isPassword ? (isShowPassword && 'text') || 'password' : 'text'}
                        className={`field__input ${this.getValueClass()}`}
                        data-name={name}
                        id={id}
                        value={checkValueOfEmpty(value) ? value : ''}
                        onChange={(e) =>
                            this.handlerInput({ action: 'change', value: e.target.value })
                        }
                        onFocus={() => {
                            if (isDisabled) {
                                // e.target.blur();
                            } else {
                                this.handlerInput({ action: 'focus' });
                            }
                        }}
                        onBlur={() => this.handlerInput({ action: 'blur' })}
                        autoComplete="off"
                        readOnly={isDisabled}
                        isRun={isRun}
                        tabIndex={isDisabled && -1}
                        style={styleText}
                    />
                )}
                <label className={`field__support ${this.getValueClass()}`} {...propsLabel}>
                    {support}
                </label>
                {isPassword && (
                    <i
                        className={`field__icon _click ${
                            isShowPassword ? '_active' : ''
                        } _password`}
                        onClick={() => {
                            this.handlerPassword();
                        }}
                    >
                        <Icon name="eye" />
                    </i>
                )}
                {iconEdit && !isPassword && (
                    <i className="field__icon _click _editIcon">
                        <Icon name="edit" />
                    </i>
                )}
                {list && (
                    <Animate className="field__list" isShow={isShowList}>
                        {list.map((item, key) => (
                            <div
                                className={`${this.getValueClass()} field__listItem`}
                                key={key}
                                onClick={(e) => {
                                    callbackList({ key });
                                    this.handlerShowList(false);
                                    e.stopPropagation();
                                }}
                            >
                                {item.content}
                            </div>
                        ))}
                    </Animate>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Field);

Field.propTypes = {
    type: PropTypes.string,
    name: PropTypes.any,
    keyName: PropTypes.string,
    support: PropTypes.string,
    value: PropTypes.string,
    tag: PropTypes.string,
    callback: PropTypes.func,
    template: PropTypes.string,
    charDel: PropTypes.string,
    charEmpty: PropTypes.string,
    validate: PropTypes.string,
    isMask: PropTypes.bool,
    error: PropTypes.string,
    reg: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    isPassword: PropTypes.bool,
    iconEdit: PropTypes.bool,
    group: PropTypes.string,
    isShowTemplate: PropTypes.bool,
    valueSupport: PropTypes.object,
    isDisabled: PropTypes.bool,
    maxLen: PropTypes.number,
    setStateComplete: PropTypes.any,
    list: PropTypes.array,
    callbackList: PropTypes.func,
    isRun: PropTypes.bool,
    min: PropTypes.object,
    max: PropTypes.object,
    isEditmode: PropTypes.bool,
    style: PropTypes.object,
    styleText: PropTypes.object,
    stopPropagation: PropTypes.bool,
};
