const list = [
    '😀',
    '😁',
    '😂',
    '😃',
    '😄',
    '😅',
    '😆',
    '😇',
    '😈',
    '😉',
    '😊',
    '😋',
    '😌',
    '😍',
    '😎',
    '😏',
    '😐',
    '😑',
    '😒',
    '😓',
    '😔',
    '😕',
    '😖',
    '😗',
    '😘',
    '😙',
    '😚',
    '😛',
    '😜',
    '😝',
    '😞',
    '😟',
    '😠',
    '😡',
    '😢',
    '😣',
    '😤',
    '😥',
    '😦',
    '😧',
    '😨',
    '😩',
    '😪',
    '😫',
    '😬',
    '😭',
    '😮',
    '😯',
    '😰',
    '😱',
    '😲',
    '😳',
    '😴',
    '😵',
    '😶',
    '😷',
    '😸',
    '😹',
    '😺',
    '😻',
    '😼',
    '😽',
    '😾',
    '😿',
    '🙀',
    '🙁',
    '🙂',
    '🙃',
    '🙄',
    '🙅🏻‍♀️',
    '🙅🏻‍♂️',
    '🙅🏻',
    '🙅🏼‍♀️',
    '🙅🏼‍♂️',
    '🙅🏼',
    '🙅🏽‍♀️',
    '🙅🏽‍♂️',
    '🙅🏽',
    '🙅🏾‍♀️',
    '🙅🏾‍♂️',
    '🙅🏾',
    '🙅🏿‍♀️',
    '🙅🏿‍♂️',
    '🙅🏿',
    '🙅‍♀️',
    '🙅‍♂️',
    '🙅',
    '🙆🏻‍♀️',
    '🙆🏻‍♂️',
    '🙆🏻',
    '🙆🏼‍♀️',
    '🙆🏼‍♂️',
    '🙆🏼',
    '🙆🏽‍♀️',
    '🙆🏽‍♂️',
    '🙆🏽',
    '🙆🏾‍♀️',
    '🙆🏾‍♂️',
    '🙆🏾',
    '🙆🏿‍♀️',
    '🙆🏿‍♂️',
    '🙆🏿',
    '🙆‍♀️',
    '🙆‍♂️',
    '🙆',
    '🙇🏻‍♀️',
    '🙇🏻‍♂️',
    '🙇🏻',
    '🙇🏼‍♀️',
    '🙇🏼‍♂️',
    '🙇🏼',
    '🙇🏽‍♀️',
    '🙇🏽‍♂️',
    '🙇🏽',
    '🙇🏾‍♀️',
    '🙇🏾‍♂️',
    '🙇🏾',
    '🙇🏿‍♀️',
    '🙇🏿‍♂️',
    '🙇🏿',
    '🙇‍♀️',
    '🙇‍♂️',
    '🙇',
    '🙈',
    '🙉',
    '🙊',
    '🙋🏻‍♀️',
    '🙋🏻‍♂️',
    '🙋🏻',
    '🙋🏼‍♀️',
    '🙋🏼‍♂️',
    '🙋🏼',
    '🙋🏽‍♀️',
    '🙋🏽‍♂️',
    '🙋🏽',
    '🙋🏾‍♀️',
    '🙋🏾‍♂️',
    '🙋🏾',
    '🙋🏿‍♀️',
    '🙋🏿‍♂️',
    '🙋🏿',
    '🙋‍♀️',
    '🙋‍♂️',
    '🙋',
    '🙌🏻',
    '🙌🏼',
    '🙌🏽',
    '🙌🏾',
    '🙌🏿',
    '🙌',
    '🙍🏻‍♀️',
    '🙍🏻‍♂️',
    '🙍🏻',
    '🙍🏼‍♀️',
    '🙍🏼‍♂️',
    '🙍🏼',
    '🙍🏽‍♀️',
    '🙍🏽‍♂️',
    '🙍🏽',
    '🙍🏾‍♀️',
    '🙍🏾‍♂️',
    '🙍🏾',
    '🙍🏿‍♀️',
    '🙍🏿‍♂️',
    '🙍🏿',
    '🙍‍♀️',
    '🙍‍♂️',
    '🙍',
    '🙎🏻‍♀️',
    '🙎🏻‍♂️',
    '🙎🏻',
    '🙎🏼‍♀️',
    '🙎🏼‍♂️',
    '🙎🏼',
    '🙎🏽‍♀️',
    '🙎🏽‍♂️',
    '🙎🏽',
    '🙎🏾‍♀️',
    '🙎🏾‍♂️',
    '🙎🏾',
    '🙎🏿‍♀️',
    '🙎🏿‍♂️',
    '🙎🏿',
    '🙎‍♀️',
    '🙎‍♂️',
    '🙎',
    '🙏🏻',
    '🙏🏼',
    '🙏🏽',
    '🙏🏾',
    '🙏🏿',
    '🙏',
    '🌀',
    '🌁',
    '🌂',
    '🌃',
    '🌄',
    '🌅',
    '🌆',
    '🌇',
    '🌈',
    '🌉',
    '🌊',
    '🌋',
    '🌌',
    '🌍',
    '🌎',
    '🌏',
    '🌐',
    '🌑',
    '🌒',
    '🌓',
    '🌔',
    '🌕',
    '🌖',
    '🌗',
    '🌘',
    '🌙',
    '🌚',
    '🌛',
    '🌜',
    '🌝',
    '🌞',
    '🌟',
    '🌠',
    '🌡️',
    '🌤️',
    '🌥️',
    '🌦️',
    '🌧️',
    '🌨️',
    '🌩️',
    '🌪️',
    '🌫️',
    '🌬️',
    '🌭',
    '🌮',
    '🌯',
    '🌰',
    '🌱',
    '🌲',
    '🌳',
    '🌴',
    '🌵',
    '🌶️',
    '🌷',
    '🌸',
    '🌹',
    '🌺',
    '🌻',
    '🌼',
    '🌽',
    '🌾',
    '🌿',
    '🍀',
    '🍁',
    '🍂',
    '🍃',
    '🍄',
    '🍅',
    '🍆',
    '🍇',
    '🍈',
    '🍉',
    '🍊',
    '🍋',
    '🍌',
    '🍍',
    '🍎',
    '🍏',
    '🍐',
    '🍑',
    '🍒',
    '🍓',
    '🍔',
    '🍕',
    '🍖',
    '🍗',
    '🍘',
    '🍙',
    '🍚',
    '🍛',
    '🍜',
    '🍝',
    '🍞',
    '🍟',
    '🍠',
    '🍡',
    '🍢',
    '🍣',
    '🍤',
    '🍥',
    '🍦',
    '🍧',
    '🍨',
    '🍩',
    '🍪',
    '🍫',
    '🍬',
    '🍭',
    '🍮',
    '🍯',
    '🍰',
    '🍱',
    '🍲',
    '🍳',
    '🍴',
    '🍵',
    '🍶',
    '🍷',
    '🍸',
    '🍹',
    '🍺',
    '🍻',
    '🍼',
    '🍽️',
    '🍾',
    '🍿',
    '🎀',
    '🎁',
    '🎂',
    '🎃',
    '🎄',
    '🎅🏻',
    '🎅🏼',
    '🎅🏽',
    '🎅🏾',
    '🎅🏿',
    '🎅',
    '🎆',
    '🎇',
    '🎈',
    '🎉',
    '🎊',
    '🎋',
    '🎌',
    '🎍',
    '🎎',
    '🎏',
    '🎐',
    '🎑',
    '🎒',
    '🎓',
    '🎖️',
    '🎗️',
    '🎙️',
    '🎚️',
    '🎛️',
    '🎞️',
    '🎟️',
    '🎠',
    '🎡',
    '🎢',
    '🎣',
    '🎤',
    '🎥',
    '🎦',
    '🎧',
    '🎨',
    '🎩',
    '🎪',
    '🎫',
    '🎬',
    '🎭',
    '🎮',
    '🎯',
    '🎰',
    '🎱',
    '🎲',
    '🎳',
    '🎴',
    '🎵',
    '🎶',
    '🎷',
    '🎸',
    '🎹',
    '🎺',
    '🎻',
    '🎼',
    '🎽',
    '🎾',
    '🎿',
    '🏀',
    '🏁',
    '🏂🏻',
    '🏂🏼',
    '🏂🏽',
    '🏂🏾',
    '🏂🏿',
    '🏂',
    '🏃🏻‍♀️',
    '🏃🏻‍♂️',
    '🏃🏻',
    '🏃🏼‍♀️',
    '🏃🏼‍♂️',
    '🏃🏼',
    '🏃🏽‍♀️',
    '🏃🏽‍♂️',
    '🏃🏽',
    '🏃🏾‍♀️',
    '🏃🏾‍♂️',
    '🏃🏾',
    '🏃🏿‍♀️',
    '🏃🏿‍♂️',
    '🏃🏿',
    '🏃‍♀️',
    '🏃‍♂️',
    '🏃',
    '🏄🏻‍♀️',
    '🏄🏻‍♂️',
    '🏄🏻',
    '🏄🏼‍♀️',
    '🏄🏼‍♂️',
    '🏄🏼',
    '🏄🏽‍♀️',
    '🏄🏽‍♂️',
    '🏄🏽',
    '🏄🏾‍♀️',
    '🏄🏾‍♂️',
    '🏄🏾',
    '🏄🏿‍♀️',
    '🏄🏿‍♂️',
    '🏄🏿',
    '🏄‍♀️',
    '🏄‍♂️',
    '🏄',
    '🏅',
    '🏆',
    '🏇🏻',
    '🏇🏼',
    '🏇🏽',
    '🏇🏾',
    '🏇🏿',
    '🏇',
    '🏈',
    '🏉',
    '🏊🏻‍♀️',
    '🏊🏻‍♂️',
    '🏊🏻',
    '🏊🏼‍♀️',
    '🏊🏼‍♂️',
    '🏊🏼',
    '🏊🏽‍♀️',
    '🏊🏽‍♂️',
    '🏊🏽',
    '🏊🏾‍♀️',
    '🏊🏾‍♂️',
    '🏊🏾',
    '🏊🏿‍♀️',
    '🏊🏿‍♂️',
    '🏊🏿',
    '🏊‍♀️',
    '🏊‍♂️',
    '🏊',
    '🏋🏻‍♀️',
    '🏋🏻‍♂️',
    '🏋🏻',
    '🏋🏼‍♀️',
    '🏋🏼‍♂️',
    '🏋🏼',
    '🏋🏽‍♀️',
    '🏋🏽‍♂️',
    '🏋🏽',
    '🏋🏾‍♀️',
    '🏋🏾‍♂️',
    '🏋🏾',
    '🏋🏿‍♀️',
    '🏋🏿‍♂️',
    '🏋🏿',
    '🏋️‍♀️',
    '🏋️‍♂️',
    '🏋️',
    '🏌🏻‍♀️',
    '🏌🏻‍♂️',
    '🏌🏻',
    '🏌🏼‍♀️',
    '🏌🏼‍♂️',
    '🏌🏼',
    '🏌🏽‍♀️',
    '🏌🏽‍♂️',
    '🏌🏽',
    '🏌🏾‍♀️',
    '🏌🏾‍♂️',
    '🏌🏾',
    '🏌🏿‍♀️',
    '🏌🏿‍♂️',
    '🏌🏿',
    '🏌️‍♀️',
    '🏌️‍♂️',
    '🏌️',
    '🏍️',
    '🏎️',
    '🏏',
    '🏐',
    '🏑',
    '🏒',
    '🏓',
    '🏔️',
    '🏕️',
    '🏖️',
    '🏗️',
    '🏘️',
    '🏙️',
    '🏚️',
    '🏛️',
    '🏜️',
    '🏝️',
    '🏞️',
    '🏟️',
    '🏠',
    '🏡',
    '🏢',
    '🏣',
    '🏤',
    '🏥',
    '🏦',
    '🏧',
    '🏨',
    '🏩',
    '🏪',
    '🏫',
    '🏬',
    '🏭',
    '🏮',
    '🏯',
    '🏰',
    '🏳️‍🌈',
    '🏳️',
    '🏴‍☠️',
    '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    '🏴',
    '🏵️',
    '🏷️',
    '🏸',
    '🏹',
    '🏺',
    '🏻',
    '🏼',
    '🏽',
    '🏾',
    '🏿',
    '🐀',
    '🐁',
    '🐂',
    '🐃',
    '🐄',
    '🐅',
    '🐆',
    '🐇',
    '🐈',
    '🐉',
    '🐊',
    '🐋',
    '🐌',
    '🐍',
    '🐎',
    '🐏',
    '🐐',
    '🐑',
    '🐒',
    '🐓',
    '🐔',
    '🐕‍🦺',
    '🐕',
    '🐖',
    '🐗',
    '🐘',
    '🐙',
    '🐚',
    '🐛',
    '🐜',
    '🐝',
    '🐞',
    '🐟',
    '🐠',
    '🐡',
    '🐢',
    '🐣',
    '🐤',
    '🐥',
    '🐦',
    '🐧',
    '🐨',
    '🐩',
    '🐪',
    '🐫',
    '🐬',
    '🐭',
    '🐮',
    '🐯',
    '🐰',
    '🐱',
    '🐲',
    '🐳',
    '🐴',
    '🐵',
    '🐶',
    '🐷',
    '🐸',
    '🐹',
    '🐺',
    '🐻',
    '🐼',
    '🐽',
    '🐾',
    '🐿️',
    '👀',
    '👁‍🗨',
    '👁️',
    '👂🏻',
    '👂🏼',
    '👂🏽',
    '👂🏾',
    '👂🏿',
    '👂',
    '👃🏻',
    '👃🏼',
    '👃🏽',
    '👃🏾',
    '👃🏿',
    '👃',
    '👄',
    '👅',
    '👆🏻',
    '👆🏼',
    '👆🏽',
    '👆🏾',
    '👆🏿',
    '👆',
    '👇🏻',
    '👇🏼',
    '👇🏽',
    '👇🏾',
    '👇🏿',
    '👇',
    '👈🏻',
    '👈🏼',
    '👈🏽',
    '👈🏾',
    '👈🏿',
    '👈',
    '👉🏻',
    '👉🏼',
    '👉🏽',
    '👉🏾',
    '👉🏿',
    '👉',
    '👊🏻',
    '👊🏼',
    '👊🏽',
    '👊🏾',
    '👊🏿',
    '👊',
    '👋🏻',
    '👋🏼',
    '👋🏽',
    '👋🏾',
    '👋🏿',
    '👋',
    '👌🏻',
    '👌🏼',
    '👌🏽',
    '👌🏾',
    '👌🏿',
    '👌',
    '👍🏻',
    '👍🏼',
    '👍🏽',
    '👍🏾',
    '👍🏿',
    '👍',
    '👎🏻',
    '👎🏼',
    '👎🏽',
    '👎🏾',
    '👎🏿',
    '👎',
    '👏🏻',
    '👏🏼',
    '👏🏽',
    '👏🏾',
    '👏🏿',
    '👏',
    '👐🏻',
    '👐🏼',
    '👐🏽',
    '👐🏾',
    '👐🏿',
    '👐',
    '👑',
    '👒',
    '👓',
    '👔',
    '👕',
    '👖',
    '👗',
    '👘',
    '👙',
    '👚',
    '👛',
    '👜',
    '👝',
    '👞',
    '👟',
    '👠',
    '👡',
    '👢',
    '👣',
    '👤',
    '👥',
    '👦🏻',
    '👦🏼',
    '👦🏽',
    '👦🏾',
    '👦🏿',
    '👦',
    '👧🏻',
    '👧🏼',
    '👧🏽',
    '👧🏾',
    '👧🏿',
    '👧',
    '👨🏻‍🌾',
    '👨🏻‍🍳',
    '👨🏻‍🎓',
    '👨🏻‍🎤',
    '👨🏻‍🎨',
    '👨🏻‍🏫',
    '👨🏻‍🏭',
    '👨🏻‍💻',
    '👨🏻‍💼',
    '👨🏻‍🔧',
    '👨🏻‍🔬',
    '👨🏻‍🚀',
    '👨🏻‍🚒',
    '👨🏻‍🦯',
    '👨🏻‍🦰',
    '👨🏻‍🦱',
    '👨🏻‍🦲',
    '👨🏻‍🦳',
    '👨🏻‍🦼',
    '👨🏻‍🦽',
    '👨🏻‍⚕️',
    '👨🏻‍⚖️',
    '👨🏻‍✈️',
    '👨🏻',
    '👨🏼‍🌾',
    '👨🏼‍🍳',
    '👨🏼‍🎓',
    '👨🏼‍🎤',
    '👨🏼‍🎨',
    '👨🏼‍🏫',
    '👨🏼‍🏭',
    '👨🏼‍💻',
    '👨🏼‍💼',
    '👨🏼‍🔧',
    '👨🏼‍🔬',
    '👨🏼‍🚀',
    '👨🏼‍🚒',
    '👨🏼‍🤝‍👨🏻',
    '👨🏼‍🦯',
    '👨🏼‍🦰',
    '👨🏼‍🦱',
    '👨🏼‍🦲',
    '👨🏼‍🦳',
    '👨🏼‍🦼',
    '👨🏼‍🦽',
    '👨🏼‍⚕️',
    '👨🏼‍⚖️',
    '👨🏼‍✈️',
    '👨🏼',
    '👨🏽‍🌾',
    '👨🏽‍🍳',
    '👨🏽‍🎓',
    '👨🏽‍🎤',
    '👨🏽‍🎨',
    '👨🏽‍🏫',
    '👨🏽‍🏭',
    '👨🏽‍💻',
    '👨🏽‍💼',
    '👨🏽‍🔧',
    '👨🏽‍🔬',
    '👨🏽‍🚀',
    '👨🏽‍🚒',
    '👨🏽‍🤝‍👨🏻',
    '👨🏽‍🤝‍👨🏼',
    '👨🏽‍🦯',
    '👨🏽‍🦰',
    '👨🏽‍🦱',
    '👨🏽‍🦲',
    '👨🏽‍🦳',
    '👨🏽‍🦼',
    '👨🏽‍🦽',
    '👨🏽‍⚕️',
    '👨🏽‍⚖️',
    '👨🏽‍✈️',
    '👨🏽',
    '👨🏾‍🌾',
    '👨🏾‍🍳',
    '👨🏾‍🎓',
    '👨🏾‍🎤',
    '👨🏾‍🎨',
    '👨🏾‍🏫',
    '👨🏾‍🏭',
    '👨🏾‍💻',
    '👨🏾‍💼',
    '👨🏾‍🔧',
    '👨🏾‍🔬',
    '👨🏾‍🚀',
    '👨🏾‍🚒',
    '👨🏾‍🤝‍👨🏻',
    '👨🏾‍🤝‍👨🏼',
    '👨🏾‍🤝‍👨🏽',
    '👨🏾‍🦯',
    '👨🏾‍🦰',
    '👨🏾‍🦱',
    '👨🏾‍🦲',
    '👨🏾‍🦳',
    '👨🏾‍🦼',
    '👨🏾‍🦽',
    '👨🏾‍⚕️',
    '👨🏾‍⚖️',
    '👨🏾‍✈️',
    '👨🏾',
    '👨🏿‍🌾',
    '👨🏿‍🍳',
    '👨🏿‍🎓',
    '👨🏿‍🎤',
    '👨🏿‍🎨',
    '👨🏿‍🏫',
    '👨🏿‍🏭',
    '👨🏿‍💻',
    '👨🏿‍💼',
    '👨🏿‍🔧',
    '👨🏿‍🔬',
    '👨🏿‍🚀',
    '👨🏿‍🚒',
    '👨🏿‍🤝‍👨🏻',
    '👨🏿‍🤝‍👨🏼',
    '👨🏿‍🤝‍👨🏽',
    '👨🏿‍🤝‍👨🏾',
    '👨🏿‍🦯',
    '👨🏿‍🦰',
    '👨🏿‍🦱',
    '👨🏿‍🦲',
    '👨🏿‍🦳',
    '👨🏿‍🦼',
    '👨🏿‍🦽',
    '👨🏿‍⚕️',
    '👨🏿‍⚖️',
    '👨🏿‍✈️',
    '👨🏿',
    '👨‍🌾',
    '👨‍🍳',
    '👨‍🎓',
    '👨‍🎤',
    '👨‍🎨',
    '👨‍🏫',
    '👨‍🏭',
    '👨‍👦‍👦',
    '👨‍👦',
    '👨‍👧‍👦',
    '👨‍👧‍👧',
    '👨‍👧',
    '👨‍👨‍👦‍👦',
    '👨‍👨‍👦',
    '👨‍👨‍👧‍👦',
    '👨‍👨‍👧‍👧',
    '👨‍👨‍👧',
    '👨‍👩‍👦‍👦',
    '👨‍👩‍👦',
    '👨‍👩‍👧‍👦',
    '👨‍👩‍👧‍👧',
    '👨‍👩‍👧',
    '👨‍💻',
    '👨‍💼',
    '👨‍🔧',
    '👨‍🔬',
    '👨‍🚀',
    '👨‍🚒',
    '👨‍🦯',
    '👨‍🦰',
    '👨‍🦱',
    '👨‍🦲',
    '👨‍🦳',
    '👨‍🦼',
    '👨‍🦽',
    '👨‍⚕️',
    '👨‍⚖️',
    '👨‍✈️',
    '👨‍❤️‍👨',
    '👨‍❤️‍💋‍👨',
    '👨',
    '👩🏻‍🌾',
    '👩🏻‍🍳',
    '👩🏻‍🎓',
    '👩🏻‍🎤',
    '👩🏻‍🎨',
    '👩🏻‍🏫',
    '👩🏻‍🏭',
    '👩🏻‍💻',
    '👩🏻‍💼',
    '👩🏻‍🔧',
    '👩🏻‍🔬',
    '👩🏻‍🚀',
    '👩🏻‍🚒',
    '👩🏻‍🤝‍👨🏼',
    '👩🏻‍🤝‍👨🏽',
    '👩🏻‍🤝‍👨🏾',
    '👩🏻‍🤝‍👨🏿',
    '👩🏻‍🦯',
    '👩🏻‍🦰',
    '👩🏻‍🦱',
    '👩🏻‍🦲',
    '👩🏻‍🦳',
    '👩🏻‍🦼',
    '👩🏻‍🦽',
    '👩🏻‍⚕️',
    '👩🏻‍⚖️',
    '👩🏻‍✈️',
    '👩🏻',
    '👩🏼‍🌾',
    '👩🏼‍🍳',
    '👩🏼‍🎓',
    '👩🏼‍🎤',
    '👩🏼‍🎨',
    '👩🏼‍🏫',
    '👩🏼‍🏭',
    '👩🏼‍💻',
    '👩🏼‍💼',
    '👩🏼‍🔧',
    '👩🏼‍🔬',
    '👩🏼‍🚀',
    '👩🏼‍🚒',
    '👩🏼‍🤝‍👨🏻',
    '👩🏼‍🤝‍👨🏽',
    '👩🏼‍🤝‍👨🏾',
    '👩🏼‍🤝‍👨🏿',
    '👩🏼‍🤝‍👩🏻',
    '👩🏼‍🦯',
    '👩🏼‍🦰',
    '👩🏼‍🦱',
    '👩🏼‍🦲',
    '👩🏼‍🦳',
    '👩🏼‍🦼',
    '👩🏼‍🦽',
    '👩🏼‍⚕️',
    '👩🏼‍⚖️',
    '👩🏼‍✈️',
    '👩🏼',
    '👩🏽‍🌾',
    '👩🏽‍🍳',
    '👩🏽‍🎓',
    '👩🏽‍🎤',
    '👩🏽‍🎨',
    '👩🏽‍🏫',
    '👩🏽‍🏭',
    '👩🏽‍💻',
    '👩🏽‍💼',
    '👩🏽‍🔧',
    '👩🏽‍🔬',
    '👩🏽‍🚀',
    '👩🏽‍🚒',
    '👩🏽‍🤝‍👨🏻',
    '👩🏽‍🤝‍👨🏼',
    '👩🏽‍🤝‍👨🏾',
    '👩🏽‍🤝‍👨🏿',
    '👩🏽‍🤝‍👩🏻',
    '👩🏽‍🤝‍👩🏼',
    '👩🏽‍🦯',
    '👩🏽‍🦰',
    '👩🏽‍🦱',
    '👩🏽‍🦲',
    '👩🏽‍🦳',
    '👩🏽‍🦼',
    '👩🏽‍🦽',
    '👩🏽‍⚕️',
    '👩🏽‍⚖️',
    '👩🏽‍✈️',
    '👩🏽',
    '👩🏾‍🌾',
    '👩🏾‍🍳',
    '👩🏾‍🎓',
    '👩🏾‍🎤',
    '👩🏾‍🎨',
    '👩🏾‍🏫',
    '👩🏾‍🏭',
    '👩🏾‍💻',
    '👩🏾‍💼',
    '👩🏾‍🔧',
    '👩🏾‍🔬',
    '👩🏾‍🚀',
    '👩🏾‍🚒',
    '👩🏾‍🤝‍👨🏻',
    '👩🏾‍🤝‍👨🏼',
    '👩🏾‍🤝‍👨🏽',
    '👩🏾‍🤝‍👨🏿',
    '👩🏾‍🤝‍👩🏻',
    '👩🏾‍🤝‍👩🏼',
    '👩🏾‍🤝‍👩🏽',
    '👩🏾‍🦯',
    '👩🏾‍🦰',
    '👩🏾‍🦱',
    '👩🏾‍🦲',
    '👩🏾‍🦳',
    '👩🏾‍🦼',
    '👩🏾‍🦽',
    '👩🏾‍⚕️',
    '👩🏾‍⚖️',
    '👩🏾‍✈️',
    '👩🏾',
    '👩🏿‍🌾',
    '👩🏿‍🍳',
    '👩🏿‍🎓',
    '👩🏿‍🎤',
    '👩🏿‍🎨',
    '👩🏿‍🏫',
    '👩🏿‍🏭',
    '👩🏿‍💻',
    '👩🏿‍💼',
    '👩🏿‍🔧',
    '👩🏿‍🔬',
    '👩🏿‍🚀',
    '👩🏿‍🚒',
    '👩🏿‍🤝‍👨🏻',
    '👩🏿‍🤝‍👨🏼',
    '👩🏿‍🤝‍👨🏽',
    '👩🏿‍🤝‍👨🏾',
    '👩🏿‍🤝‍👩🏻',
    '👩🏿‍🤝‍👩🏼',
    '👩🏿‍🤝‍👩🏽',
    '👩🏿‍🤝‍👩🏾',
    '👩🏿‍🦯',
    '👩🏿‍🦰',
    '👩🏿‍🦱',
    '👩🏿‍🦲',
    '👩🏿‍🦳',
    '👩🏿‍🦼',
    '👩🏿‍🦽',
    '👩🏿‍⚕️',
    '👩🏿‍⚖️',
    '👩🏿‍✈️',
    '👩🏿',
    '👩‍🌾',
    '👩‍🍳',
    '👩‍🎓',
    '👩‍🎤',
    '👩‍🎨',
    '👩‍🏫',
    '👩‍🏭',
    '👩‍👦‍👦',
    '👩‍👦',
    '👩‍👧‍👦',
    '👩‍👧‍👧',
    '👩‍👧',
    '👩‍👩‍👦‍👦',
    '👩‍👩‍👦',
    '👩‍👩‍👧‍👦',
    '👩‍👩‍👧‍👧',
    '👩‍👩‍👧',
    '👩‍💻',
    '👩‍💼',
    '👩‍🔧',
    '👩‍🔬',
    '👩‍🚀',
    '👩‍🚒',
    '👩‍🦯',
    '👩‍🦰',
    '👩‍🦱',
    '👩‍🦲',
    '👩‍🦳',
    '👩‍🦼',
    '👩‍🦽',
    '👩‍⚕️',
    '👩‍⚖️',
    '👩‍✈️',
    '👩‍❤️‍👨',
    '👩‍❤️‍👩',
    '👩‍❤️‍💋‍👨',
    '👩‍❤️‍💋‍👩',
    '👩',
    '👪',
    '👫🏻',
    '👫🏼',
    '👫🏽',
    '👫🏾',
    '👫🏿',
    '👫',
    '👬🏻',
    '👬🏼',
    '👬🏽',
    '👬🏾',
    '👬🏿',
    '👬',
    '👭🏻',
    '👭🏼',
    '👭🏽',
    '👭🏾',
    '👭🏿',
    '👭',
    '👮🏻‍♀️',
    '👮🏻‍♂️',
    '👮🏻',
    '👮🏼‍♀️',
    '👮🏼‍♂️',
    '👮🏼',
    '👮🏽‍♀️',
    '👮🏽‍♂️',
    '👮🏽',
    '👮🏾‍♀️',
    '👮🏾‍♂️',
    '👮🏾',
    '👮🏿‍♀️',
    '👮🏿‍♂️',
    '👮🏿',
    '👮‍♀️',
    '👮‍♂️',
    '👮',
    '👯‍♀️',
    '👯‍♂️',
    '👯',
    '👰🏻',
    '👰🏼',
    '👰🏽',
    '👰🏾',
    '👰🏿',
    '👰',
    '👱🏻‍♀️',
    '👱🏻‍♂️',
    '👱🏻',
    '👱🏼‍♀️',
    '👱🏼‍♂️',
    '👱🏼',
    '👱🏽‍♀️',
    '👱🏽‍♂️',
    '👱🏽',
    '👱🏾‍♀️',
    '👱🏾‍♂️',
    '👱🏾',
    '👱🏿‍♀️',
    '👱🏿‍♂️',
    '👱🏿',
    '👱‍♀️',
    '👱‍♂️',
    '👱',
    '👲🏻',
    '👲🏼',
    '👲🏽',
    '👲🏾',
    '👲🏿',
    '👲',
    '👳🏻‍♀️',
    '👳🏻‍♂️',
    '👳🏻',
    '👳🏼‍♀️',
    '👳🏼‍♂️',
    '👳🏼',
    '👳🏽‍♀️',
    '👳🏽‍♂️',
    '👳🏽',
    '👳🏾‍♀️',
    '👳🏾‍♂️',
    '👳🏾',
    '👳🏿‍♀️',
    '👳🏿‍♂️',
    '👳🏿',
    '👳‍♀️',
    '👳‍♂️',
    '👳',
    '👴🏻',
    '👴🏼',
    '👴🏽',
    '👴🏾',
    '👴🏿',
    '👴',
    '👵🏻',
    '👵🏼',
    '👵🏽',
    '👵🏾',
    '👵🏿',
    '👵',
    '👶🏻',
    '👶🏼',
    '👶🏽',
    '👶🏾',
    '👶🏿',
    '👶',
    '👷🏻‍♀️',
    '👷🏻‍♂️',
    '👷🏻',
    '👷🏼‍♀️',
    '👷🏼‍♂️',
    '👷🏼',
    '👷🏽‍♀️',
    '👷🏽‍♂️',
    '👷🏽',
    '👷🏾‍♀️',
    '👷🏾‍♂️',
    '👷🏾',
    '👷🏿‍♀️',
    '👷🏿‍♂️',
    '👷🏿',
    '👷‍♀️',
    '👷‍♂️',
    '👷',
    '👸🏻',
    '👸🏼',
    '👸🏽',
    '👸🏾',
    '👸🏿',
    '👸',
    '👹',
    '👺',
    '👻',
    '👼🏻',
    '👼🏼',
    '👼🏽',
    '👼🏾',
    '👼🏿',
    '👼',
    '👽',
    '👾',
    '👿',
    '💀',
    '💁🏻‍♀️',
    '💁🏻‍♂️',
    '💁🏻',
    '💁🏼‍♀️',
    '💁🏼‍♂️',
    '💁🏼',
    '💁🏽‍♀️',
    '💁🏽‍♂️',
    '💁🏽',
    '💁🏾‍♀️',
    '💁🏾‍♂️',
    '💁🏾',
    '💁🏿‍♀️',
    '💁🏿‍♂️',
    '💁🏿',
    '💁‍♀️',
    '💁‍♂️',
    '💁',
    '💂🏻‍♀️',
    '💂🏻‍♂️',
    '💂🏻',
    '💂🏼‍♀️',
    '💂🏼‍♂️',
    '💂🏼',
    '💂🏽‍♀️',
    '💂🏽‍♂️',
    '💂🏽',
    '💂🏾‍♀️',
    '💂🏾‍♂️',
    '💂🏾',
    '💂🏿‍♀️',
    '💂🏿‍♂️',
    '💂🏿',
    '💂‍♀️',
    '💂‍♂️',
    '💂',
    '💃🏻',
    '💃🏼',
    '💃🏽',
    '💃🏾',
    '💃🏿',
    '💃',
    '💄',
    '💅🏻',
    '💅🏼',
    '💅🏽',
    '💅🏾',
    '💅🏿',
    '💅',
    '💆🏻‍♀️',
    '💆🏻‍♂️',
    '💆🏻',
    '💆🏼‍♀️',
    '💆🏼‍♂️',
    '💆🏼',
    '💆🏽‍♀️',
    '💆🏽‍♂️',
    '💆🏽',
    '💆🏾‍♀️',
    '💆🏾‍♂️',
    '💆🏾',
    '💆🏿‍♀️',
    '💆🏿‍♂️',
    '💆🏿',
    '💆‍♀️',
    '💆‍♂️',
    '💆',
    '💇🏻‍♀️',
    '💇🏻‍♂️',
    '💇🏻',
    '💇🏼‍♀️',
    '💇🏼‍♂️',
    '💇🏼',
    '💇🏽‍♀️',
    '💇🏽‍♂️',
    '💇🏽',
    '💇🏾‍♀️',
    '💇🏾‍♂️',
    '💇🏾',
    '💇🏿‍♀️',
    '💇🏿‍♂️',
    '💇🏿',
    '💇‍♀️',
    '💇‍♂️',
    '💇',
    '💈',
    '💉',
    '💊',
    '💋',
    '💌',
    '💍',
    '💎',
    '💏',
    '💐',
    '💑',
    '💒',
    '💓',
    '💔',
    '💕',
    '💖',
    '💗',
    '💘',
    '💙',
    '💚',
    '💛',
    '💜',
    '💝',
    '💞',
    '💟',
    '💠',
    '💡',
    '💢',
    '💣',
    '💤',
    '💥',
    '💦',
    '💧',
    '💨',
    '💩',
    '💪🏻',
    '💪🏼',
    '💪🏽',
    '💪🏾',
    '💪🏿',
    '💪',
    '💫',
    '💬',
    '💭',
    '💮',
    '💯',
    '💰',
    '💱',
    '💲',
    '💳',
    '💴',
    '💵',
    '💶',
    '💷',
    '💸',
    '💹',
    '💺',
    '💻',
    '💼',
    '💽',
    '💾',
    '💿',
    '📀',
    '📁',
    '📂',
    '📃',
    '📄',
    '📅',
    '📆',
    '📇',
    '📈',
    '📉',
    '📊',
    '📋',
    '📌',
    '📍',
    '📎',
    '📏',
    '📐',
    '📑',
    '📒',
    '📓',
    '📔',
    '📕',
    '📖',
    '📗',
    '📘',
    '📙',
    '📚',
    '📛',
    '📜',
    '📝',
    '📞',
    '📟',
    '📠',
    '📡',
    '📢',
    '📣',
    '📤',
    '📥',
    '📦',
    '📧',
    '📨',
    '📩',
    '📪',
    '📫',
    '📬',
    '📭',
    '📮',
    '📯',
    '📰',
    '📱',
    '📲',
    '📳',
    '📴',
    '📵',
    '📶',
    '📷',
    '📸',
    '📹',
    '📺',
    '📻',
    '📼',
    '📽️',
    '📿',
    '🔀',
    '🔁',
    '🔂',
    '🔃',
    '🔄',
    '🔅',
    '🔆',
    '🔇',
    '🔈',
    '🔉',
    '🔊',
    '🔋',
    '🔌',
    '🔍',
    '🔎',
    '🔏',
    '🔐',
    '🔑',
    '🔒',
    '🔓',
    '🔔',
    '🔕',
    '🔖',
    '🔗',
    '🔘',
    '🔙',
    '🔚',
    '🔛',
    '🔜',
    '🔝',
    '🔞',
    '🔟',
    '🔠',
    '🔡',
    '🔢',
    '🔣',
    '🔤',
    '🔥',
    '🔦',
    '🔧',
    '🔨',
    '🔩',
    '🔪',
    '🔫',
    '🔬',
    '🔭',
    '🔮',
    '🔯',
    '🔰',
    '🔱',
    '🔲',
    '🔳',
    '🔴',
    '🔵',
    '🔶',
    '🔷',
    '🔸',
    '🔹',
    '🔺',
    '🔻',
    '🔼',
    '🔽',
    '🕉️',
    '🕊️',
    '🕋',
    '🕌',
    '🕍',
    '🕎',
    '🕐',
    '🕑',
    '🕒',
    '🕓',
    '🕔',
    '🕕',
    '🕖',
    '🕗',
    '🕘',
    '🕙',
    '🕚',
    '🕛',
    '🕜',
    '🕝',
    '🕞',
    '🕟',
    '🕠',
    '🕡',
    '🕢',
    '🕣',
    '🕤',
    '🕥',
    '🕦',
    '🕧',
    '🕯️',
    '🕰️',
    '🕳️',
    '🕴🏻‍♀️',
    '🕴🏻‍♂️',
    '🕴🏻',
    '🕴🏼‍♀️',
    '🕴🏼‍♂️',
    '🕴🏼',
    '🕴🏽‍♀️',
    '🕴🏽‍♂️',
    '🕴🏽',
    '🕴🏾‍♀️',
    '🕴🏾‍♂️',
    '🕴🏾',
    '🕴🏿‍♀️',
    '🕴🏿‍♂️',
    '🕴🏿',
    '🕴️‍♀️',
    '🕴️‍♂️',
    '🕴️',
    '🕵🏻‍♀️',
    '🕵🏻‍♂️',
    '🕵🏻',
    '🕵🏼‍♀️',
    '🕵🏼‍♂️',
    '🕵🏼',
    '🕵🏽‍♀️',
    '🕵🏽‍♂️',
    '🕵🏽',
    '🕵🏾‍♀️',
    '🕵🏾‍♂️',
    '🕵🏾',
    '🕵🏿‍♀️',
    '🕵🏿‍♂️',
    '🕵🏿',
    '🕵️‍♀️',
    '🕵️‍♂️',
    '🕵️',
    '🕶️',
    '🕷️',
    '🕸️',
    '🕹️',
    '🕺🏻',
    '🕺🏼',
    '🕺🏽',
    '🕺🏾',
    '🕺🏿',
    '🕺',
    '🖇️',
    '🖊️',
    '🖋️',
    '🖌️',
    '🖍️',
    '🖐🏻',
    '🖐🏼',
    '🖐🏽',
    '🖐🏾',
    '🖐🏿',
    '🖐️',
    '🖕🏻',
    '🖕🏼',
    '🖕🏽',
    '🖕🏾',
    '🖕🏿',
    '🖕',
    '🖖🏻',
    '🖖🏼',
    '🖖🏽',
    '🖖🏾',
    '🖖🏿',
    '🖖',
    '🖤',
    '🖥️',
    '🖨️',
    '🖱️',
    '🖲️',
    '🖼️',
    '🗂️',
    '🗃️',
    '🗄️',
    '🗑️',
    '🗒️',
    '🗓️',
    '🗜️',
    '🗝️',
    '🗞️',
    '🗡️',
    '🗣️',
    '🗨️',
    '🗯️',
    '🗳️',
    '🗺️',
    '🗻',
    '🗼',
    '🗽',
    '🗾',
    '🗿',
    '🚀',
    '🚁',
    '🚂',
    '🚃',
    '🚄',
    '🚅',
    '🚆',
    '🚇',
    '🚈',
    '🚉',
    '🚊',
    '🚋',
    '🚌',
    '🚍',
    '🚎',
    '🚏',
    '🚐',
    '🚑',
    '🚒',
    '🚓',
    '🚔',
    '🚕',
    '🚖',
    '🚗',
    '🚘',
    '🚙',
    '🚚',
    '🚛',
    '🚜',
    '🚝',
    '🚞',
    '🚟',
    '🚠',
    '🚡',
    '🚢',
    '🚣🏻‍♀️',
    '🚣🏻‍♂️',
    '🚣🏻',
    '🚣🏼‍♀️',
    '🚣🏼‍♂️',
    '🚣🏼',
    '🚣🏽‍♀️',
    '🚣🏽‍♂️',
    '🚣🏽',
    '🚣🏾‍♀️',
    '🚣🏾‍♂️',
    '🚣🏾',
    '🚣🏿‍♀️',
    '🚣🏿‍♂️',
    '🚣🏿',
    '🚣‍♀️',
    '🚣‍♂️',
    '🚣',
    '🚤',
    '🚥',
    '🚦',
    '🚧',
    '🚨',
    '🚩',
    '🚪',
    '🚫',
    '🚬',
    '🚭',
    '🚮',
    '🚯',
    '🚰',
    '🚱',
    '🚲',
    '🚳',
    '🚴🏻‍♀️',
    '🚴🏻‍♂️',
    '🚴🏻',
    '🚴🏼‍♀️',
    '🚴🏼‍♂️',
    '🚴🏼',
    '🚴🏽‍♀️',
    '🚴🏽‍♂️',
    '🚴🏽',
    '🚴🏾‍♀️',
    '🚴🏾‍♂️',
    '🚴🏾',
    '🚴🏿‍♀️',
    '🚴🏿‍♂️',
    '🚴🏿',
    '🚴‍♀️',
    '🚴‍♂️',
    '🚴',
    '🚵🏻‍♀️',
    '🚵🏻‍♂️',
    '🚵🏻',
    '🚵🏼‍♀️',
    '🚵🏼‍♂️',
    '🚵🏼',
    '🚵🏽‍♀️',
    '🚵🏽‍♂️',
    '🚵🏽',
    '🚵🏾‍♀️',
    '🚵🏾‍♂️',
    '🚵🏾',
    '🚵🏿‍♀️',
    '🚵🏿‍♂️',
    '🚵🏿',
    '🚵‍♀️',
    '🚵‍♂️',
    '🚵',
    '🚶🏻‍♀️',
    '🚶🏻‍♂️',
    '🚶🏻',
    '🚶🏼‍♀️',
    '🚶🏼‍♂️',
    '🚶🏼',
    '🚶🏽‍♀️',
    '🚶🏽‍♂️',
    '🚶🏽',
    '🚶🏾‍♀️',
    '🚶🏾‍♂️',
    '🚶🏾',
    '🚶🏿‍♀️',
    '🚶🏿‍♂️',
    '🚶🏿',
    '🚶‍♀️',
    '🚶‍♂️',
    '🚶',
    '🚷',
    '🚸',
    '🚹',
    '🚺',
    '🚻',
    '🚼',
    '🚽',
    '🚾',
    '🚿',
    '🛀🏻',
    '🛀🏼',
    '🛀🏽',
    '🛀🏾',
    '🛀🏿',
    '🛀',
    '🛁',
    '🛂',
    '🛃',
    '🛄',
    '🛅',
    '🛋️',
    '🛌🏻',
    '🛌🏼',
    '🛌🏽',
    '🛌🏾',
    '🛌🏿',
    '🛌',
    '🛍️',
    '🛎️',
    '🛏️',
    '🛐',
    '🛑',
    '🛒',
    '🛕',
    '🛠️',
    '🛡️',
    '🛢️',
    '🛣️',
    '🛤️',
    '🛥️',
    '🛩️',
    '🛫',
    '🛬',
    '🛰️',
    '🛳️',
    '🛴',
    '🛵',
    '🛶',
    '🛷',
    '🛸',
    '🛹',
    '🛺',
    '🟠',
    '🟡',
    '🟢',
    '🟣',
    '🟤',
    '🟥',
    '🟦',
    '🟧',
    '🟨',
    '🟩',
    '🟪',
    '🟫',
    '🤍',
    '🤎',
    '🤏🏻',
    '🤏🏼',
    '🤏🏽',
    '🤏🏾',
    '🤏🏿',
    '🤏',
    '🤐',
    '🤑',
    '🤒',
    '🤓',
    '🤔',
    '🤕',
    '🤖',
    '🤗',
    '🤘🏻',
    '🤘🏼',
    '🤘🏽',
    '🤘🏾',
    '🤘🏿',
    '🤘',
    '🤙🏻',
    '🤙🏼',
    '🤙🏽',
    '🤙🏾',
    '🤙🏿',
    '🤙',
    '🤚🏻',
    '🤚🏼',
    '🤚🏽',
    '🤚🏾',
    '🤚🏿',
    '🤚',
    '🤛🏻',
    '🤛🏼',
    '🤛🏽',
    '🤛🏾',
    '🤛🏿',
    '🤛',
    '🤜🏻',
    '🤜🏼',
    '🤜🏽',
    '🤜🏾',
    '🤜🏿',
    '🤜',
    '🤝',
    '🤞🏻',
    '🤞🏼',
    '🤞🏽',
    '🤞🏾',
    '🤞🏿',
    '🤞',
    '🤟🏻',
    '🤟🏼',
    '🤟🏽',
    '🤟🏾',
    '🤟🏿',
    '🤟',
    '🤠',
    '🤡',
    '🤢',
    '🤣',
    '🤤',
    '🤥',
    '🤦🏻‍♀️',
    '🤦🏻‍♂️',
    '🤦🏻',
    '🤦🏼‍♀️',
    '🤦🏼‍♂️',
    '🤦🏼',
    '🤦🏽‍♀️',
    '🤦🏽‍♂️',
    '🤦🏽',
    '🤦🏾‍♀️',
    '🤦🏾‍♂️',
    '🤦🏾',
    '🤦🏿‍♀️',
    '🤦🏿‍♂️',
    '🤦🏿',
    '🤦‍♀️',
    '🤦‍♂️',
    '🤦',
    '🤧',
    '🤨',
    '🤩',
    '🤪',
    '🤫',
    '🤬',
    '🤭',
    '🤮',
    '🤯',
    '🤰🏻',
    '🤰🏼',
    '🤰🏽',
    '🤰🏾',
    '🤰🏿',
    '🤰',
    '🤱🏻',
    '🤱🏼',
    '🤱🏽',
    '🤱🏾',
    '🤱🏿',
    '🤱',
    '🤲🏻',
    '🤲🏼',
    '🤲🏽',
    '🤲🏾',
    '🤲🏿',
    '🤲',
    '🤳🏻',
    '🤳🏼',
    '🤳🏽',
    '🤳🏾',
    '🤳🏿',
    '🤳',
    '🤴🏻',
    '🤴🏼',
    '🤴🏽',
    '🤴🏾',
    '🤴🏿',
    '🤴',
    '🤵🏻‍♀️',
    '🤵🏻‍♂️',
    '🤵🏻',
    '🤵🏼‍♀️',
    '🤵🏼‍♂️',
    '🤵🏼',
    '🤵🏽‍♀️',
    '🤵🏽‍♂️',
    '🤵🏽',
    '🤵🏾‍♀️',
    '🤵🏾‍♂️',
    '🤵🏾',
    '🤵🏿‍♀️',
    '🤵🏿‍♂️',
    '🤵🏿',
    '🤵‍♀️',
    '🤵‍♂️',
    '🤵',
    '🤶🏻',
    '🤶🏼',
    '🤶🏽',
    '🤶🏾',
    '🤶🏿',
    '🤶',
    '🤷🏻‍♀️',
    '🤷🏻‍♂️',
    '🤷🏻',
    '🤷🏼‍♀️',
    '🤷🏼‍♂️',
    '🤷🏼',
    '🤷🏽‍♀️',
    '🤷🏽‍♂️',
    '🤷🏽',
    '🤷🏾‍♀️',
    '🤷🏾‍♂️',
    '🤷🏾',
    '🤷🏿‍♀️',
    '🤷🏿‍♂️',
    '🤷🏿',
    '🤷‍♀️',
    '🤷‍♂️',
    '🤷',
    '🤸🏻‍♀️',
    '🤸🏻‍♂️',
    '🤸🏻',
    '🤸🏼‍♀️',
    '🤸🏼‍♂️',
    '🤸🏼',
    '🤸🏽‍♀️',
    '🤸🏽‍♂️',
    '🤸🏽',
    '🤸🏾‍♀️',
    '🤸🏾‍♂️',
    '🤸🏾',
    '🤸🏿‍♀️',
    '🤸🏿‍♂️',
    '🤸🏿',
    '🤸‍♀️',
    '🤸‍♂️',
    '🤸',
    '🤹🏻‍♀️',
    '🤹🏻‍♂️',
    '🤹🏻',
    '🤹🏼‍♀️',
    '🤹🏼‍♂️',
    '🤹🏼',
    '🤹🏽‍♀️',
    '🤹🏽‍♂️',
    '🤹🏽',
    '🤹🏾‍♀️',
    '🤹🏾‍♂️',
    '🤹🏾',
    '🤹🏿‍♀️',
    '🤹🏿‍♂️',
    '🤹🏿',
    '🤹‍♀️',
    '🤹‍♂️',
    '🤹',
    '🤺',
    '🤼‍♀️',
    '🤼‍♂️',
    '🤼',
    '🤽🏻‍♀️',
    '🤽🏻‍♂️',
    '🤽🏻',
    '🤽🏼‍♀️',
    '🤽🏼‍♂️',
    '🤽🏼',
    '🤽🏽‍♀️',
    '🤽🏽‍♂️',
    '🤽🏽',
    '🤽🏾‍♀️',
    '🤽🏾‍♂️',
    '🤽🏾',
    '🤽🏿‍♀️',
    '🤽🏿‍♂️',
    '🤽🏿',
    '🤽‍♀️',
    '🤽‍♂️',
    '🤽',
    '🤾🏻‍♀️',
    '🤾🏻‍♂️',
    '🤾🏻',
    '🤾🏼‍♀️',
    '🤾🏼‍♂️',
    '🤾🏼',
    '🤾🏽‍♀️',
    '🤾🏽‍♂️',
    '🤾🏽',
    '🤾🏾‍♀️',
    '🤾🏾‍♂️',
    '🤾🏾',
    '🤾🏿‍♀️',
    '🤾🏿‍♂️',
    '🤾🏿',
    '🤾‍♀️',
    '🤾‍♂️',
    '🤾',
    '🤿',
    '🥀',
    '🥁',
    '🥂',
    '🥃',
    '🥄',
    '🥅',
    '🥇',
    '🥈',
    '🥉',
    '🥊',
    '🥋',
    '🥌',
    '🥍',
    '🥎',
    '🥏',
    '🥐',
    '🥑',
    '🥒',
    '🥓',
    '🥔',
    '🥕',
    '🥖',
    '🥗',
    '🥘',
    '🥙',
    '🥚',
    '🥛',
    '🥜',
    '🥝',
    '🥞',
    '🥟',
    '🥠',
    '🥡',
    '🥢',
    '🥣',
    '🥤',
    '🥥',
    '🥦',
    '🥧',
    '🥨',
    '🥩',
    '🥪',
    '🥫',
    '🥬',
    '🥭',
    '🥮',
    '🥯',
    '🥰',
    '🥱',
    '🥳',
    '🥴',
    '🥵',
    '🥶',
    '🥺',
    '🥻',
    '🥼',
    '🥽',
    '🥾',
    '🥿',
    '🦀',
    '🦁',
    '🦂',
    '🦃',
    '🦄',
    '🦅',
    '🦆',
    '🦇',
    '🦈',
    '🦉',
    '🦊',
    '🦋',
    '🦌',
    '🦍',
    '🦎',
    '🦏',
    '🦐',
    '🦑',
    '🦒',
    '🦓',
    '🦔',
    '🦕',
    '🦖',
    '🦗',
    '🦘',
    '🦙',
    '🦚',
    '🦛',
    '🦜',
    '🦝',
    '🦞',
    '🦟',
    '🦠',
    '🦡',
    '🦢',
    '🦥',
    '🦦',
    '🦧',
    '🦨',
    '🦩',
    '🦪',
    '🦮',
    '🦯',
    '🦰',
    '🦱',
    '🦲',
    '🦳',
    '🦴',
    '🦵🏻',
    '🦵🏼',
    '🦵🏽',
    '🦵🏾',
    '🦵🏿',
    '🦵',
    '🦶🏻',
    '🦶🏼',
    '🦶🏽',
    '🦶🏾',
    '🦶🏿',
    '🦶',
    '🦷',
    '🦸🏻‍♀️',
    '🦸🏻‍♂️',
    '🦸🏻',
    '🦸🏼‍♀️',
    '🦸🏼‍♂️',
    '🦸🏼',
    '🦸🏽‍♀️',
    '🦸🏽‍♂️',
    '🦸🏽',
    '🦸🏾‍♀️',
    '🦸🏾‍♂️',
    '🦸🏾',
    '🦸🏿‍♀️',
    '🦸🏿‍♂️',
    '🦸🏿',
    '🦸‍♀️',
    '🦸‍♂️',
    '🦸',
    '🦹🏻‍♀️',
    '🦹🏻‍♂️',
    '🦹🏻',
    '🦹🏼‍♀️',
    '🦹🏼‍♂️',
    '🦹🏼',
    '🦹🏽‍♀️',
    '🦹🏽‍♂️',
    '🦹🏽',
    '🦹🏾‍♀️',
    '🦹🏾‍♂️',
    '🦹🏾',
    '🦹🏿‍♀️',
    '🦹🏿‍♂️',
    '🦹🏿',
    '🦹‍♀️',
    '🦹‍♂️',
    '🦹',
    '🦺',
    '🦻🏻',
    '🦻🏼',
    '🦻🏽',
    '🦻🏾',
    '🦻🏿',
    '🦻',
    '🦼',
    '🦽',
    '🦾',
    '🦿',
    '🧀',
    '🧁',
    '🧂',
    '🧃',
    '🧄',
    '🧅',
    '🧆',
    '🧇',
    '🧈',
    '🧉',
    '🧊',
    '🧍🏻‍♀️',
    '🧍🏻‍♂️',
    '🧍🏻',
    '🧍🏼‍♀️',
    '🧍🏼‍♂️',
    '🧍🏼',
    '🧍🏽‍♀️',
    '🧍🏽‍♂️',
    '🧍🏽',
    '🧍🏾‍♀️',
    '🧍🏾‍♂️',
    '🧍🏾',
    '🧍🏿‍♀️',
    '🧍🏿‍♂️',
    '🧍🏿',
    '🧍‍♀️',
    '🧍‍♂️',
    '🧍',
    '🧎🏻‍♀️',
    '🧎🏻‍♂️',
    '🧎🏻',
    '🧎🏼‍♀️',
    '🧎🏼‍♂️',
    '🧎🏼',
    '🧎🏽‍♀️',
    '🧎🏽‍♂️',
    '🧎🏽',
    '🧎🏾‍♀️',
    '🧎🏾‍♂️',
    '🧎🏾',
    '🧎🏿‍♀️',
    '🧎🏿‍♂️',
    '🧎🏿',
    '🧎‍♀️',
    '🧎‍♂️',
    '🧎',
    '🧏🏻‍♀️',
    '🧏🏻‍♂️',
    '🧏🏻',
    '🧏🏼‍♀️',
    '🧏🏼‍♂️',
    '🧏🏼',
    '🧏🏽‍♀️',
    '🧏🏽‍♂️',
    '🧏🏽',
    '🧏🏾‍♀️',
    '🧏🏾‍♂️',
    '🧏🏾',
    '🧏🏿‍♀️',
    '🧏🏿‍♂️',
    '🧏🏿',
    '🧏‍♀️',
    '🧏‍♂️',
    '🧏',
    '🧐',
    '🧑🏻‍🤝‍🧑🏻',
    '🧑🏻',
    '🧑🏼‍🤝‍🧑🏻',
    '🧑🏼‍🤝‍🧑🏼',
    '🧑🏼',
    '🧑🏽‍🤝‍🧑🏻',
    '🧑🏽‍🤝‍🧑🏼',
    '🧑🏽‍🤝‍🧑🏽',
    '🧑🏽',
    '🧑🏾‍🤝‍🧑🏻',
    '🧑🏾‍🤝‍🧑🏼',
    '🧑🏾‍🤝‍🧑🏽',
    '🧑🏾‍🤝‍🧑🏾',
    '🧑🏾',
    '🧑🏿‍🤝‍🧑🏻',
    '🧑🏿‍🤝‍🧑🏼',
    '🧑🏿‍🤝‍🧑🏽',
    '🧑🏿‍🤝‍🧑🏾',
    '🧑🏿‍🤝‍🧑🏿',
    '🧑🏿',
    '🧑‍🤝‍🧑',
    '🧑',
    '🧒🏻',
    '🧒🏼',
    '🧒🏽',
    '🧒🏾',
    '🧒🏿',
    '🧒',
    '🧓🏻',
    '🧓🏼',
    '🧓🏽',
    '🧓🏾',
    '🧓🏿',
    '🧓',
    '🧔🏻',
    '🧔🏼',
    '🧔🏽',
    '🧔🏾',
    '🧔🏿',
    '🧔',
    '🧕🏻',
    '🧕🏼',
    '🧕🏽',
    '🧕🏾',
    '🧕🏿',
    '🧕',
    '🧖🏻‍♀️',
    '🧖🏻‍♂️',
    '🧖🏻',
    '🧖🏼‍♀️',
    '🧖🏼‍♂️',
    '🧖🏼',
    '🧖🏽‍♀️',
    '🧖🏽‍♂️',
    '🧖🏽',
    '🧖🏾‍♀️',
    '🧖🏾‍♂️',
    '🧖🏾',
    '🧖🏿‍♀️',
    '🧖🏿‍♂️',
    '🧖🏿',
    '🧖‍♀️',
    '🧖‍♂️',
    '🧖',
    '🧗🏻‍♀️',
    '🧗🏻‍♂️',
    '🧗🏻',
    '🧗🏼‍♀️',
    '🧗🏼‍♂️',
    '🧗🏼',
    '🧗🏽‍♀️',
    '🧗🏽‍♂️',
    '🧗🏽',
    '🧗🏾‍♀️',
    '🧗🏾‍♂️',
    '🧗🏾',
    '🧗🏿‍♀️',
    '🧗🏿‍♂️',
    '🧗🏿',
    '🧗‍♀️',
    '🧗‍♂️',
    '🧗',
    '🧘🏻‍♀️',
    '🧘🏻‍♂️',
    '🧘🏻',
    '🧘🏼‍♀️',
    '🧘🏼‍♂️',
    '🧘🏼',
    '🧘🏽‍♀️',
    '🧘🏽‍♂️',
    '🧘🏽',
    '🧘🏾‍♀️',
    '🧘🏾‍♂️',
    '🧘🏾',
    '🧘🏿‍♀️',
    '🧘🏿‍♂️',
    '🧘🏿',
    '🧘‍♀️',
    '🧘‍♂️',
    '🧘',
    '🧙🏻‍♀️',
    '🧙🏻‍♂️',
    '🧙🏻',
    '🧙🏼‍♀️',
    '🧙🏼‍♂️',
    '🧙🏼',
    '🧙🏽‍♀️',
    '🧙🏽‍♂️',
    '🧙🏽',
    '🧙🏾‍♀️',
    '🧙🏾‍♂️',
    '🧙🏾',
    '🧙🏿‍♀️',
    '🧙🏿‍♂️',
    '🧙🏿',
    '🧙‍♀️',
    '🧙‍♂️',
    '🧙',
    '🧚🏻‍♀️',
    '🧚🏻‍♂️',
    '🧚🏻',
    '🧚🏼‍♀️',
    '🧚🏼‍♂️',
    '🧚🏼',
    '🧚🏽‍♀️',
    '🧚🏽‍♂️',
    '🧚🏽',
    '🧚🏾‍♀️',
    '🧚🏾‍♂️',
    '🧚🏾',
    '🧚🏿‍♀️',
    '🧚🏿‍♂️',
    '🧚🏿',
    '🧚‍♀️',
    '🧚‍♂️',
    '🧚',
    '🧛🏻‍♀️',
    '🧛🏻‍♂️',
    '🧛🏻',
    '🧛🏼‍♀️',
    '🧛🏼‍♂️',
    '🧛🏼',
    '🧛🏽‍♀️',
    '🧛🏽‍♂️',
    '🧛🏽',
    '🧛🏾‍♀️',
    '🧛🏾‍♂️',
    '🧛🏾',
    '🧛🏿‍♀️',
    '🧛🏿‍♂️',
    '🧛🏿',
    '🧛‍♀️',
    '🧛‍♂️',
    '🧛',
    '🧜🏻‍♀️',
    '🧜🏻‍♂️',
    '🧜🏻',
    '🧜🏼‍♀️',
    '🧜🏼‍♂️',
    '🧜🏼',
    '🧜🏽‍♀️',
    '🧜🏽‍♂️',
    '🧜🏽',
    '🧜🏾‍♀️',
    '🧜🏾‍♂️',
    '🧜🏾',
    '🧜🏿‍♀️',
    '🧜🏿‍♂️',
    '🧜🏿',
    '🧜‍♀️',
    '🧜‍♂️',
    '🧜',
    '🧝🏻‍♀️',
    '🧝🏻‍♂️',
    '🧝🏻',
    '🧝🏼‍♀️',
    '🧝🏼‍♂️',
    '🧝🏼',
    '🧝🏽‍♀️',
    '🧝🏽‍♂️',
    '🧝🏽',
    '🧝🏾‍♀️',
    '🧝🏾‍♂️',
    '🧝🏾',
    '🧝🏿‍♀️',
    '🧝🏿‍♂️',
    '🧝🏿',
    '🧝‍♀️',
    '🧝‍♂️',
    '🧝',
    '🧞‍♀️',
    '🧞‍♂️',
    '🧞',
    '🧟‍♀️',
    '🧟‍♂️',
    '🧟',
    '🧠',
    '🧡',
    '🧢',
    '🧣',
    '🧤',
    '🧥',
    '🧦',
    '🧧',
    '🧨',
    '🧩',
    '🧪',
    '🧫',
    '🧬',
    '🧭',
    '🧮',
    '🧯',
    '🧰',
    '🧱',
    '🧲',
    '🧳',
    '🧴',
    '🧵',
    '🧶',
    '🧷',
    '🧸',
    '🧹',
    '🧺',
    '🧻',
    '🧼',
    '🧽',
    '🧾',
    '🧿',
    '🩰',
    '🩱',
    '🩲',
    '🩳',
    '🩸',
    '🩹',
    '🩺',
    '🪀',
    '🪁',
    '🪂',
    '🪐',
    '🪑',
    '🪒',
    '🪓',
    '🪔',
    '🪕',
    '‼️',
    '⁉️',
    '™️',
    'ℹ️',
    '↔️',
    '↕️',
    '↖️',
    '↗️',
    '↘️',
    '↙️',
    '↩️',
    '↪️',
    '#⃣',
    '⌚️',
    '⌛️',
    '⌨️',
    '⏏️',
    '⏩',
    '⏪',
    '⏫',
    '⏬',
    '⏭️',
    '⏮️',
    '⏯️',
    '⏰',
    '⏱️',
    '⏲️',
    '⏳',
    '⏸️',
    '⏹️',
    '⏺️',
    'Ⓜ️',
    '▪️',
    '▫️',
    '▶️',
    '◀️',
    '◻️',
    '◼️',
    '◽️',
    '◾️',
    '☀️',
    '☁️',
    '☂️',
    '☃️',
    '☄️',
    '☎️',
    '☑️',
    '☔️',
    '☕️',
    '☘️',
    '☝🏻',
    '☝🏼',
    '☝🏽',
    '☝🏾',
    '☝🏿',
    '☝️',
    '☠️',
    '☢️',
    '☣️',
    '☦️',
    '☪️',
    '☮️',
    '☯️',
    '☸️',
    '☹️',
    '☺️',
    '♀️',
    '♂️',
    '♈️',
    '♉️',
    '♊️',
    '♋️',
    '♌️',
    '♍️',
    '♎️',
    '♏️',
    '♐️',
    '♑️',
    '♒️',
    '♓️',
    '♟️',
    '♠️',
    '♣️',
    '♥️',
    '♦️',
    '♨️',
    '♻️',
    '♾',
    '♿️',
    '⚒️',
    '⚓️',
    '⚔️',
    '⚕️',
    '⚖️',
    '⚗️',
    '⚙️',
    '⚛️',
    '⚜️',
    '⚠️',
    '⚡️',
    '⚪️',
    '⚫️',
    '⚰️',
    '⚱️',
    '⚽️',
    '⚾️',
    '⛄️',
    '⛅️',
    '⛈️',
    '⛎',
    '⛏️',
    '⛑️',
    '⛓️',
    '⛔️',
    '⛩️',
    '⛪️',
    '⛰️',
    '⛱️',
    '⛲️',
    '⛳️',
    '⛴️',
    '⛵️',
    '⛷🏻',
    '⛷🏼',
    '⛷🏽',
    '⛷🏾',
    '⛷🏿',
    '⛷️',
    '⛸️',
    '⛹🏻‍♀️',
    '⛹🏻‍♂️',
    '⛹🏻',
    '⛹🏼‍♀️',
    '⛹🏼‍♂️',
    '⛹🏼',
    '⛹🏽‍♀️',
    '⛹🏽‍♂️',
    '⛹🏽',
    '⛹🏾‍♀️',
    '⛹🏾‍♂️',
    '⛹🏾',
    '⛹🏿‍♀️',
    '⛹🏿‍♂️',
    '⛹🏿',
    '⛹️‍♀️',
    '⛹️‍♂️',
    '⛹️',
    '⛺️',
    '⛽️',
    '✂️',
    '✅',
    '✈️',
    '✉️',
    '✊🏻',
    '✊🏼',
    '✊🏽',
    '✊🏾',
    '✊🏿',
    '✊',
    '✋🏻',
    '✋🏼',
    '✋🏽',
    '✋🏾',
    '✋🏿',
    '✋',
    '✌🏻',
    '✌🏼',
    '✌🏽',
    '✌🏾',
    '✌🏿',
    '✌️',
    '✍🏻',
    '✍🏼',
    '✍🏽',
    '✍🏾',
    '✍🏿',
    '✍️',
    '✏️',
    '✒️',
    '✔️',
    '✖️',
    '✝️',
    '✡️',
    '✨',
    '✳️',
    '✴️',
    '❄️',
    '❇️',
    '❌',
    '❎',
    '❓',
    '❔',
    '❕',
    '❗️',
    '❣️',
    '❤️',
    '➕',
    '➖',
    '➗',
    '➡️',
    '➰',
    '➿',
    '⤴️',
    '⤵️',
    '⬅️',
    '⬆️',
    '⬇️',
    '⬛️',
    '⬜️',
    '⭐️',
    '⭕️',
    '0⃣',
    '〰️',
    '〽️',
    '㊗️',
    '㊙️',
    '🀄️',
    '🃏',
    '🅰️',
    '🅱️',
    '🅾️',
    '🅿️',
    '🆎',
    '🆑',
    '🆒',
    '🆓',
    '🆔',
    '🆕',
    '🆖',
    '🆗',
    '🆘',
    '🆙',
    '🆚',
    '🇦🇨',
    '🇦🇩',
    '🇦🇪',
    '🇦🇫',
    '🇦🇬',
    '🇦🇮',
    '🇦🇱',
    '🇦🇲',
    '🇦🇴',
    '🇦🇶',
    '🇦🇷',
    '🇦🇸',
    '🇦🇹',
    '🇦🇺',
    '🇦🇼',
    '🇦🇽',
    '🇦🇿',
    '🇦',
    '🇧🇦',
    '🇧🇧',
    '🇧🇩',
    '🇧🇪',
    '🇧🇫',
    '🇧🇬',
    '🇧🇭',
    '🇧🇮',
    '🇧🇯',
    '🇧🇱',
    '🇧🇲',
    '🇧🇳',
    '🇧🇴',
    '🇧🇶',
    '🇧🇷',
    '🇧🇸',
    '🇧🇹',
    '🇧🇻',
    '🇧🇼',
    '🇧🇾',
    '🇧🇿',
    '🇧',
    '🇨🇦',
    '🇨🇨',
    '🇨🇩',
    '🇨🇫',
    '🇨🇬',
    '🇨🇭',
    '🇨🇮',
    '🇨🇰',
    '🇨🇱',
    '🇨🇲',
    '🇨🇳',
    '🇨🇴',
    '🇨🇵',
    '🇨🇷',
    '🇨🇺',
    '🇨🇻',
    '🇨🇼',
    '🇨🇽',
    '🇨🇾',
    '🇨🇿',
    '🇨',
    '🇩🇪',
    '🇩🇬',
    '🇩🇯',
    '🇩🇰',
    '🇩🇲',
    '🇩🇴',
    '🇩🇿',
    '🇩',
    '🇪🇦',
    '🇪🇨',
    '🇪🇪',
    '🇪🇬',
    '🇪🇭',
    '🇪🇷',
    '🇪🇸',
    '🇪🇹',
    '🇪🇺',
    '🇪',
    '🇫🇮',
    '🇫🇯',
    '🇫🇰',
    '🇫🇲',
    '🇫🇴',
    '🇫🇷',
    '🇫',
    '🇬🇦',
    '🇬🇧',
    '🇬🇩',
    '🇬🇪',
    '🇬🇫',
    '🇬🇬',
    '🇬🇭',
    '🇬🇮',
    '🇬🇱',
    '🇬🇲',
    '🇬🇳',
    '🇬🇵',
    '🇬🇶',
    '🇬🇷',
    '🇬🇸',
    '🇬🇹',
    '🇬🇺',
    '🇬🇼',
    '🇬🇾',
    '🇬',
    '🇭🇰',
    '🇭🇲',
    '🇭🇳',
    '🇭🇷',
    '🇭🇹',
    '🇭🇺',
    '🇭',
    '🇮🇨',
    '🇮🇩',
    '🇮🇪',
    '🇮🇱',
    '🇮🇲',
    '🇮🇳',
    '🇮🇴',
    '🇮🇶',
    '🇮🇷',
    '🇮🇸',
    '🇮🇹',
    '🇮',
    '🇯🇪',
    '🇯🇲',
    '🇯🇴',
    '🇯🇵',
    '🇯',
    '🇰🇪',
    '🇰🇬',
    '🇰🇭',
    '🇰🇮',
    '🇰🇲',
    '🇰🇳',
    '🇰🇵',
    '🇰🇷',
    '🇰🇼',
    '🇰🇾',
    '🇰🇿',
    '🇰',
    '🇱🇦',
    '🇱🇧',
    '🇱🇨',
    '🇱🇮',
    '🇱🇰',
    '🇱🇷',
    '🇱🇸',
    '🇱🇹',
    '🇱🇺',
    '🇱🇻',
    '🇱🇾',
    '🇱',
    '🇲🇦',
    '🇲🇨',
    '🇲🇩',
    '🇲🇪',
    '🇲🇫',
    '🇲🇬',
    '🇲🇭',
    '🇲🇰',
    '🇲🇱',
    '🇲🇲',
    '🇲🇳',
    '🇲🇴',
    '🇲🇵',
    '🇲🇶',
    '🇲🇷',
    '🇲🇸',
    '🇲🇹',
    '🇲🇺',
    '🇲🇻',
    '🇲🇼',
    '🇲🇽',
    '🇲🇾',
    '🇲🇿',
    '🇲',
    '🇳🇦',
    '🇳🇨',
    '🇳🇪',
    '🇳🇫',
    '🇳🇬',
    '🇳🇮',
    '🇳🇱',
    '🇳🇴',
    '🇳🇵',
    '🇳🇷',
    '🇳🇺',
    '🇳🇿',
    '🇳',
    '🇴🇲',
    '🇴',
    '🇵🇦',
    '🇵🇪',
    '🇵🇫',
    '🇵🇬',
    '🇵🇭',
    '🇵🇰',
    '🇵🇱',
    '🇵🇲',
    '🇵🇳',
    '🇵🇷',
    '🇵🇸',
    '🇵🇹',
    '🇵🇼',
    '🇵🇾',
    '🇵',
    '🇶🇦',
    '🇶',
    '🇷🇪',
    '🇷🇴',
    '🇷🇸',
    '🇷🇺',
    '🇷🇼',
    '🇷',
    '🇸🇦',
    '🇸🇧',
    '🇸🇨',
    '🇸🇩',
    '🇸🇪',
    '🇸🇬',
    '🇸🇭',
    '🇸🇮',
    '🇸🇯',
    '🇸🇰',
    '🇸🇱',
    '🇸🇲',
    '🇸🇳',
    '🇸🇴',
    '🇸🇷',
    '🇸🇸',
    '🇸🇹',
    '🇸🇻',
    '🇸🇽',
    '🇸🇾',
    '🇸🇿',
    '🇸',
    '🇹🇦',
    '🇹🇨',
    '🇹🇩',
    '🇹🇫',
    '🇹🇬',
    '🇹🇭',
    '🇹🇯',
    '🇹🇰',
    '🇹🇱',
    '🇹🇲',
    '🇹🇳',
    '🇹🇴',
    '🇹🇷',
    '🇹🇹',
    '🇹🇻',
    '🇹🇼',
    '🇹🇿',
    '🇹',
    '🇺🇦',
    '🇺🇬',
    '🇺🇲',
    '🇺🇳',
    '🇺🇸',
    '🇺🇾',
    '🇺🇿',
    '🇺',
    '🇻🇦',
    '🇻🇨',
    '🇻🇪',
    '🇻🇬',
    '🇻🇮',
    '🇻🇳',
    '🇻🇺',
    '🇻',
    '🇼🇫',
    '🇼🇸',
    '🇼',
    '🇽🇰',
    '🇽',
    '🇾🇪',
    '🇾🇹',
    '🇾',
    '🇿🇦',
    '🇿🇲',
    '🇿🇼',
    '🇿',
    '🈁',
    '🈂️',
    '🈚️',
    '🈯️',
    '🈲',
    '🈳',
    '🈴',
    '🈵',
    '🈶',
    '🈷️',
    '🈸',
    '🈹',
    '🈺',
    '🉐',
    '🉑',
];

export default list;
