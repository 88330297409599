import handlerPopup from '@functions/handlerPopup';

type ParamsT = {
    version: number;
};

export default function newVersionPopupHandler({ version }: ParamsT): void {
    const textVersion = version.toString().split('').join('.');

    handlerPopup('alertPopup', {
        isShow: true,
        type: 'newVersion',
        info: textVersion,
        callback: () => {
            window.location.reload();
        },
        closeCallback: () => {
            setTimeout(() => {
                newVersionPopupHandler({ version });
            }, 60_000 * 10);
        },
    });
}
