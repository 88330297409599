import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon/Icon.tsx';

class Contract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { contract } = this.props;

        return (
            <a
                className="contract _row _click"
                href={`${process.env.REACT_APP_STATIC}/corporations-docs/${contract.fileName}`}
                target="_blank"
                rel="noreferrer"
            >
                <i className="contract__icon">
                    <Icon name="file-type-doc" />
                </i>
                <div className="contract__name">{contract.name}</div>
            </a>
        );
    }
}

export default Contract;

Contract.propTypes = {
    contract: PropTypes.object,
};
