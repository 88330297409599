import React from 'react';

import EmodziList from '@components/emodziList/EmodziList.tsx';

import CursorSupportI from '../types.ts';

const renderItem: CursorSupportI['renderItem'] = function ({ item }) {
    const fullItem = this.props.cursorEmodziList.find((innerItem) => innerItem._id === item._id);

    return (
        <div
            className={`v2cursorEmodziList__item _${item.dir} ${item.className || ''}`}
            style={
                item.position && {
                    left: `${item.position.left}px`,
                    top: `${item.position.top}px`,
                }
            }
        >
            <div className="v2cursorEmodziList__itemBack" />
            <EmodziList callback={fullItem?.callback} startPosition={0} />
        </div>
    );
};

export default renderItem;
