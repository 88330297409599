import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';
import ExecutorOrg from '@components/crm/ExecutorOrg.jsx';
import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import LinkI from '@components/link/types.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import PayT from '@global/models/Pay.ts';
import { CursorActionsItemT } from '@global/types.ts';
import { PageNamesT } from '@redux/pages.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

import Status from './pays/Status.tsx';

const renderPays: RenderColsT<'pays'> = function ({ name: colName, item }) {
    const pay = item as PayT;
    const { loadings } = this.state;
    const { name, otherData, currentsHandler, currents } = this.props;
    const executorId = otherData?.executorId as string;
    let content;
    let className;
    const {
        error,
        userName,
        executorPhone,
        hasDublicates,
        transactionErrors,
        cardNumber,
        hasAmountDublicates,
    } = pay;
    const isAdmin = this.checkAdmin();

    const renderDate = () => (
        <>
            <div className="v2table__text _block">
                {getFormatedDate({ date: new Date(pay.date), isShortYear: true })}
            </div>
        </>
    );

    if (colName === 'number') {
        const supportProps = setCursorSupport(
            {
                _id: `number-${pay._id}`,
                content: 'Акт не требует подписания<br/>со стороны исполнителя',
                dir: 'top',
            },
            { targetClassName: '.v2table__checkboxIcon', className: '' },
        );

        const renderIcon = () => (
            <div
                className={`v2table__checkboxIcon ${name === 'signPays' ? '_static' : ''}`}
                {...supportProps}
            >
                <Icon name="noExecutorSign-act" />
            </div>
        );

        content = (
            <>
                <div className="v2table__checkbox _COL">
                    {name === 'pays' && (
                        <>
                            {pay.executorSign.isNotSign && renderIcon()}
                            <div className="v2table__checkboxBox">
                                <Checkbox
                                    name={pay._id}
                                    value={
                                        !!currents!.find((innerItem) => innerItem.id === pay._id)
                                    }
                                    onChange={() => {
                                        currentsHandler!(pay._id, pay);
                                    }}
                                />
                            </div>
                        </>
                    )}

                    <div className="v2table__text _block">{pay.number}</div>
                    <div className="v2table__text _fullWidth _block">
                        <div className="v2table__checkboxExecutor">
                            <ExecutorOrg name={item.executorOrganization} />
                        </div>
                        {pay.executorSign.isNotSign && name === 'signPays' && renderIcon()}
                    </div>
                </div>
            </>
        );
    }

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{pay.name || '–'}</div>
            </>
        );
    }

    if (colName === 'executor') {
        const userNameIsError = error === 'executor-not-found' && item.status !== 'completed';

        type LinkT = {
            pageName: PageNamesT['default'];
            ids?: Record<string, string>;
            callback?: LinkI['props']['callback'];
        };

        const userNameProps: LinkT =
            userNameIsError || !pay.executorId || executorId
                ? ({} as LinkT)
                : error !== 'wallet-empty'
                  ? { pageName: 'manual-executors-inner-main', ids: { '3': pay.executorId } }
                  : { pageName: 'manual-executors-inner-wallets', ids: { '3': pay.executorId } };

        if (userNameProps.pageName) {
            userNameProps.callback = () => {
                handlerPopup('paySignPopup', { isShow: false });
            };
        }

        const ExecutorTag = userNameIsError || !pay.executorId || executorId ? 'div' : Link;
        const cardNumberIsError =
            (error === 'wallet-error' ||
                error === 'wallet-empty' ||
                transactionErrors?.includes('cardNumber') ||
                transactionErrors?.includes('cardError')) &&
            pay.status !== 'completed';
        const WalletsTag = pay.executorId ? Link : 'div';
        const cardProps: LinkT = pay.executorId
            ? { pageName: 'manual-executors-inner-wallets', ids: { '3': pay.executorId } }
            : ({} as LinkT);

        const dublicatesSupportProps = setCursorSupport(
            {
                _id: `вublicates-${pay._id}`,
                content: 'Есть дубликаты по ФИО',
                dir: 'top',
            },
            { targetClassName: '.v2table__textAlert', className: '' },
        );

        content = (
            <>
                <ExecutorTag
                    className={`v2table__text ${
                        Object.keys(userNameProps).length ? '_link _full' : ''
                    } ${userNameIsError ? '_errorColor' : ''}`}
                    {...userNameProps}
                >
                    {userName || executorPhone}

                    {hasDublicates && (
                        <div className="v2table__textAlert" {...dublicatesSupportProps}>
                            <Icon name="alert" />
                        </div>
                    )}
                </ExecutorTag>
                <WalletsTag
                    className={`v2table__text _top _minSize ${
                        cardNumberIsError ? '_errorColor' : ''
                    } _block _link _empty ${!cardNumberIsError && item.isUpdate ? '_update' : ''}`}
                    {...cardProps}
                >
                    {cardNumber || '–'}
                </WalletsTag>
            </>
        );
    }

    if (colName === 'period') {
        content = (
            <>
                <div className="v2table__text">
                    {pay.startPeriod && pay.endPeriod ? (
                        <>
                            {getFormatedDate({
                                date: new Date(pay.startPeriod),
                                isShortYear: true,
                            })}{' '}
                            <br />
                            {getFormatedDate({
                                date: new Date(pay.endPeriod),
                                isShortYear: true,
                            })}
                        </>
                    ) : (
                        <>–</>
                    )}
                </div>
            </>
        );
    }

    if (colName === 'amount') {
        const supportProps =
            pay.beforeCommission &&
            setCursorSupport(
                {
                    _id: `amount-${pay._id}`,
                    content: `Сумма с комиссией<br/>Комиссия — ${+pay.beforeCommission.toFixed(2)} ₽`,
                    dir: 'top',
                },
                { targetClassName: '.v2table__text', className: '' },
            );

        const amountDublicatesSupportProps = setCursorSupport(
            {
                _id: `amountDublicates-${pay._id}`,
                content: 'Есть дубликаты<br/>по сумме и назначению',
                dir: 'top',
            },
            { targetClassName: '.v2table__alert', className: '' },
        );
        const taxSupportProps = setCursorSupport(
            {
                _id: `tax-${pay._id}`,
                content: `С данной суммы удержано ${getFormatPrice(
                    item.taxAmount,
                )} ₽<br/>в налоговую копилку`,
                dir: 'top',
            },
            { targetClassName: '.v2table__tax', className: '' },
        );
        const projectDiff = pay.startAmount ? pay.startAmount - pay.amount : 0;
        const projectSupportProps =
            projectDiff > 0
                ? setCursorSupport(
                      {
                          _id: `project-amount-${pay._id}`,
                          content: `По проекту удержано ${getFormatPrice(projectDiff)} ₽</br> Исходная сумма — ${getFormatPrice(pay.startAmount)} ₽`,
                          dir: 'top',
                      },
                      { targetClassName: '.v2table__textInfo', className: '' },
                  )
                : null;

        content = (
            <>
                {name === 'signPays' ? (
                    <>
                        <div className="v2table__text">
                            {getFormatPrice(
                                +(+(pay.beforeCommission || 0) + +pay.amount)?.toFixed(2),
                            )}
                            &nbsp;₽
                        </div>
                        {pay.beforeCommission && (
                            <div className="v2table__text _grey _block">
                                Комиссия {getFormatPrice(+(+pay.beforeCommission)?.toFixed(2))}
                                &nbsp;₽
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={`v2table__text ${projectDiff ? '_block' : ''}`}>
                            {getFormatPrice(+pay.amount?.toFixed(2))}&nbsp;₽{' '}
                            {projectDiff > 0 && (
                                <i
                                    className="v2table__textInfo _CLICK _grey"
                                    {...projectSupportProps}
                                >
                                    <Icon name="info" />
                                </i>
                            )}
                        </div>{' '}
                        {pay.beforeCommission && !executorId && (
                            <div className="v2table__text _grey _NOWRAP" {...supportProps}>
                                {getFormatPrice(+(+pay.beforeCommission + +pay.amount)?.toFixed(2))}
                                &nbsp;₽
                            </div>
                        )}
                    </>
                )}
                {pay.taxAmount && (
                    <div className="v2table__colFlex _COL">
                        <div className="v2table__tax _row" {...taxSupportProps}>
                            TAX
                            <i className="v2table__taxIcon">
                                <Icon name="app-settings-fns-tax" />
                            </i>
                        </div>
                    </div>
                )}
                {isAdmin && renderDate()}
                {hasAmountDublicates && (
                    <div className="v2table__colFlex _COL">
                        <div className="v2table__alert _minSize" {...amountDublicatesSupportProps}>
                            <i className="v2table__alertIcon">
                                <Icon name="alert" />
                            </i>
                            Дубликат
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (colName === 'date') {
        content = <>{renderDate()}</>;
    }

    if (colName === 'signStatus') {
        content = (
            <>
                <Status
                    pay={pay}
                    type="sign"
                    loadingHandler={this.loadingHandler.bind(this)}
                    loadings={loadings}
                />
            </>
        );
    }

    if (colName === 'payStatus') {
        const getMoreActions = otherData?.getMoreActions as (data: {
            pay: PayT;
        }) => CursorActionsItemT[];

        content = (
            <>
                <Status
                    pay={pay}
                    loadingHandler={this.loadingHandler.bind(this)}
                    loadings={loadings}
                />
                <More
                    id={`pay-${pay._id}`}
                    items={getMoreActions({ pay })}
                    // stopPropagation={true}
                />
            </>
        );
    }

    return { content, className };
};

export default renderPays;
