import React from 'react';

import Switch from '@components/switch/Switch.tsx';
import copyInBuffer from '@functions/copyInBuffer.js';
import { setCursorSupport } from '@functions/supportHandler.ts';
import JoinScriptT from '@global/models/JoinScript.ts';

import MainI from '../../../../../views/crm/joins/components/scripts/components/Main/types.ts';

import { RenderColsT } from '../../types.ts';

const renderJoinsScripts: RenderColsT<'joinsScripts'> = function ({ name: colName, item }) {
    const { loadings } = this.state;
    const { otherData } = this.props;

    let content;
    let className;
    const isAdmin = this.checkAdmin();
    const joinScript = item as JoinScriptT;

    if (colName === 'active') {
        const setActive = otherData?.setActive as MainI['setActive'];
        const supportProps =
            joinScript.isDisabled &&
            setCursorSupport(
                {
                    _id: `disabled-${joinScript._id}`,
                    content: 'В данный момент<br/>проводятся подключения',
                    dir: 'bottom',
                },
                { targetClassName: '.v2table__switch', className: '' },
            );

        content = (
            <>
                <div
                    className="v2table__switch"
                    {...supportProps}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Switch
                        className="_mediumSize _green"
                        value={joinScript.isActive}
                        name="isActive"
                        disabled={joinScript.isDisabled || !!loadings[joinScript._id]}
                        onChange={async () => {
                            await this.loadingHandler({ name: joinScript._id, loading: true });

                            try {
                                await setActive({ joinScript, isActive: !joinScript.isActive });
                            } catch (error) {}

                            await this.loadingHandler({ name: joinScript._id, loading: undefined });
                        }}
                    />
                </div>
            </>
        );
    }

    if (colName === 'name') {
        const supportProps = setCursorSupport(
            {
                _id: `link-${joinScript._id}`,
                content: 'Скопировать ссылку',
                dir: 'bottom',
            },
            { targetClassName: '.v2table__text', className: '' },
        );

        className = '_innerCol';

        content = (
            <>
                <div className="v2table__text _block _bottom _mediumWeight">{joinScript.name}</div>
                <div
                    className="v2table__text _grey _minSize _CLICK"
                    onClick={(e) => {
                        e.stopPropagation();

                        copyInBuffer(`${process.env.REACT_APP_APP_DOMEN}/join/${item.link}`);
                    }}
                    {...supportProps}
                >
                    /join/{joinScript.link}
                </div>
            </>
        );
    }

    if (colName === 'template') {
        content = (
            <>
                <div className="v2table__text">
                    {joinScript.templateName}

                    {isAdmin && (
                        <>, документы: {joinScript.docs.map((doc) => doc.name).join(', ')}</>
                    )}
                </div>
            </>
        );
    }

    if (colName === 'docs') {
        content = (
            <>
                <div className="v2table__text">
                    {joinScript.docs.map((doc) => doc.name).join(', ')}
                </div>
            </>
        );
    }

    if (colName === 'counter') {
        content = (
            <>
                <div className="v2table__text">{joinScript.joinCounter || 0}</div>
            </>
        );
    }

    return { content, className };
};

export default renderJoinsScripts;
