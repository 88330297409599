import React from 'react';
import PropTypes from 'prop-types';

import Icon from './icon/Icon.tsx';

class AppInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isAll } = this.props;
        let type = navigator.userAgent.toLowerCase().includes('iphone')
            ? 'iphone'
            : navigator.userAgent.toLowerCase().includes('android')
            ? 'android'
            : 'all';

        if (isAll) {
            type = 'all';
        }

        return (
            <div className="appInstruction">
                {(type === 'all' || type === 'iphone') && (
                    <div className="appInstruction__block _col">
                        <div className="appInstruction__blockTitle">
                            Для iPhone
                            <i className="appInstruction__blockTitleIcon">
                                <Icon name="app-instruction-apple" />
                            </i>
                        </div>
                        <div className="appInstruction__blockList">
                            <div className="appInstruction__blockListItem">
                                В браузере Safari откройте
                                <br />
                                <a
                                    href={`${process.env.REACT_APP_APP_DOMEN}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    ссылку
                                </a>
                                . Внизу экрана нажмите
                                <br />
                                на кнопку «Поделиться»
                                <div className="appInstruction__blockListItemIcon">
                                    <div className="appInstruction__blockListItemIconInner _col">
                                        <i className="appInstruction__blockListItemIconItem">
                                            <Icon name="app-instruction-upload" />
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="appInstruction__blockListItem">
                                В открывшемся окне нажмите
                                <br />
                                «На экран домой», а после —<br />
                                «Добавить».
                            </div>
                        </div>
                    </div>
                )}
                {(type === 'all' || type === 'android') && (
                    <div className="appInstruction__block _col">
                        <div className="appInstruction__blockTitle">
                            Для Android
                            <i className="appInstruction__blockTitleIcon">
                                <Icon name="app-instruction-android" />
                            </i>
                        </div>
                        <div className="appInstruction__blockList">
                            <div className="appInstruction__blockListItem">
                                В браузере откройте
                                <br />
                                <a
                                    href={`${process.env.REACT_APP_APP_DOMEN}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    ссылку
                                </a>
                                . Внизу экрана нажмите
                                <br />
                                на кнопку «Меню»
                                <div className="appInstruction__blockListItemIcon">
                                    <div className="appInstruction__blockListItemIconInner _col">
                                        <i className="appInstruction__blockListItemIconItem _menu">
                                            <Icon name="app-instruction-menu" />
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div className="appInstruction__blockListItem">
                                В открывшемся окне нажмите
                                <br />
                                кнопку «+Добавить страницу»
                                <br />— «Экран приложений»
                            </div>
                        </div>
                    </div>
                )}
                {/* <div className="appInstruction__result">
                    <b>Готово!</b>
                    <br />
                    Теперь у вас есть быстрый
                    <br />
                    доступ к LIVECARGO
                </div> */}
            </div>
        );
    }
}

export default AppInstruction;

AppInstruction.propTypes = {
    isAll: PropTypes.bool,
};
