import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Icon from '@components/icon/Icon';

import Animate from '../../../components/Animate.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import ListScroll from '../../../components/ListScroll.jsx';
import Table from '../../../components/crm/manual/Table.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getFormatPrice from '../../../functions/getFormatPrice';
import getFormatedDate from '../../../functions/getFormatedDate';
import setSpacesInText from '../../../functions/setSpacesInText';

import getExecutorBalances from '../../../requests/getExecutorBalances';

class Balance extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderCard = this.renderCard.bind(this);
    }

    stepCounter = 12;

    classNameItem = '.balance__card';

    renderCard({ item }) {
        return (
            <div
                className={`balance__card _col ${item.amount < 0 ? '_minus' : ''}`}
                onClick={() => {
                    if (0) {
                        handlerPopup({ name: 'appBalancePopup', isShow: true, card: item });
                    }
                }}
            >
                <div className="balance__cardInner">
                    <div className="balance__cardTitle">
                        {item.amount > 0 ? '+' : ''}
                        {getFormatPrice(item.amount)}₽
                    </div>
                    <p
                        className="balance__cardDescription"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(item.content) }}
                    ></p>
                    <div className="balance__cardDate">
                        {getFormatedDate({ date: new Date(item.createdDate) })}
                    </div>
                    {false && (
                        <i className="balance__cardIcon">
                            <Icon name="app-touch" />
                        </i>
                    )}
                </div>
            </div>
        );
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getExecutorBalances(query).then(
                ({ balances, isLimit, counter }) => {
                    this.setItems(balances, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.getItems();
        }, 500);
    }

    render() {
        const { isReady, isDisabledScroll = false, items = [], isLimit } = this.state;
        const { handlerLoaderList } = this.props;

        return (
            <div ref={this.parent} className={`balance ${isReady ? '_ready' : ''}`}>
                <Animate className="headPage__empty" isShow={isReady && items.length === 0}>
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">У вас пока нет выплат</div>
                            <p className="empty__content">
                                После выполнения заказов
                                <br />
                                выплаты будут появляться здесь
                            </p>
                        </div>
                    </div>
                </Animate>
                <ListScroll
                    isInit={isReady}
                    getParent={() => this.parent.current?.closest('.headPage__innerBox')}
                    callback={this.getMoreItems}
                    startCounter={this.stepCounter}
                    stepCounter={this.stepCounter}
                    maxCounter={Infinity}
                    lengthCurrent={items.length}
                    handlerLoaderList={handlerLoaderList}
                    isLimit={isLimit}
                    isDisabledScroll={isDisabledScroll || !isReady}
                    keyUpdate={items.length}
                >
                    <ListAbsoluteMain
                        className="balance__cards _col"
                        items={items}
                        renderItem={this.renderCard}
                        classNameItem="balance__card"
                        prop="_id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                    />
                </ListScroll>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Balance);

Balance.propTypes = {
    user: PropTypes.object,
    handlerLoaderList: PropTypes.func,
};
