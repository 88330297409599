import React from 'react';

import { FieldT } from '@components/crm/widgetCard/static/cards.ts';
import WidgetField from '@components/crm/widgetField/WidgetField.tsx';

import I from '../types.ts';

const renderField: I['renderField'] = function ({ name }) {
    const { error, joinScript } = this.state;
    const model = this.state.model!;
    const field = this.fields[name] as FieldT;
    let disabled = false;

    if (
        joinScript &&
        ['SMZ', 'IP'].includes(joinScript.executorOrganization) &&
        ['firstName', 'secondName', 'thirdName'].includes(name)
    ) {
        disabled = true;
    }

    return (
        <div className={`join__blockField ${error?.name === name ? '_error' : ''}`}>
            <div className="join__blockFieldBox">
                <WidgetField
                    model={model}
                    name={name}
                    prop={name}
                    type={field.type}
                    onChange={async (fields) => {
                        await this.change.call(this, fields);
                    }}
                    cardName="join"
                    value={model![name]}
                    reg={field.reg}
                    regExp={field.regExp}
                    inputSupport={field.inputSupport}
                    selectText={model[field.selectTextKey!]}
                    selectList={field.selectList}
                    selectProps={field.selectProps}
                    iserror={error?.name === name}
                    defaultQuery={field.defaultQuery}
                    data={{ organization: joinScript?.executorOrganization }}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default renderField;
