import axios from 'axios';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import verificationPopupHandler from '@functions/crm/verificationPopupHandler';
import handlerPopup from '@functions/handlerPopup';
import { ReqResponseT, StoreT } from '@global/types';
import { dispatcher, store } from '@redux/redux';

import changePage from './functions/changePage';
import checkIos from './functions/checkIos';
import getStartUrl from './functions/getStartUrl';
import resize from './functions/handlerSize';
import setNotification from './functions/setNotification';
import setTryNotification from './functions/setTryNotification';
import setVariables from './functions/setVariables';

import pwaInit from './pwa/init';
import pages from './redux/pages';
import Root from './views/root/Root';

// window.isAppDevice =
//     /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
//         navigator.userAgent,
//     );

const resultPages: StoreT['pages'] = {} as StoreT['pages'];

const path = getStartUrl(window.location.pathname.slice(1));

const seo = window.location.search
    ?.slice(1)
    ?.split('&')
    ?.find((item) => item.split('=')[0] === 'seo');

pages.forEach((page) => {
    resultPages[page.name] = {
        isShow: false,
    };
});

(async () => {
    const { storePages, levels, pagesIds } = await changePage({
        href: path,
        storePages: resultPages,
        start: true,
    });

    if (levels?.[0]) {
        (document.querySelector('body') as HTMLElement).classList.add(`_${levels[0]}`);
    }

    dispatcher({ type: 'pages', data: storePages });
    dispatcher({ type: 'levels', data: levels });
    dispatcher({ type: 'pagesIds', data: pagesIds });
    dispatcher({ type: 'rootInit', data: true });
})();

axios.interceptors.response.use(
    (response) => {
        const { data, success } = response.data as ReqResponseT<{
            message: string;
            actionCode: number;
            time?: number;
        }>;

        if (data) {
            if (!success) {
                const { message, actionCode } = data;

                if (message === 'Permission denied' && actionCode === 403) {
                    setNotification({ notification: 'permission-denied' });
                } else if (message === 'Server not available') {
                    handlerPopup('alertPopup', {
                        isShow: true,
                        type: 'serverNotAvailable',
                    });
                } else if (message === 'Try latter') {
                    setTryNotification({ time: data.time });
                } else if (message === 'Corporation not verify') {
                    if (process.env.REACT_APP_SYSTEM === 'crm') {
                        verificationPopupHandler({});
                    } else {
                        setNotification({ notification: 'corporation-not-verify' });
                    }
                } else if (message === 'Process already start') {
                    setNotification({ notification: 'process-already-start' });
                }
            }
        }

        return response;
    },
    (error) => {
        throw new Error(error);
    },
);

document.addEventListener('scroll', () => {
    resize();
});

window.addEventListener('resize', () => {
    resize();

    setTimeout(() => {
        resize();
    }, 10);

    setVariables();
});

document.oncontextmenu = (e) => {
    e.preventDefault();
};

resize(true);

const loads: {
    event?: boolean;
    fonts?: boolean;
} = {};
const checkLoad = () => {
    if (loads.event && loads.fonts) {
        setTimeout(() => {
            dispatcher({ type: 'windowIsLoad', data: true });
            dispatcher({ type: 'windowIsReady', data: true });

            document.dispatchEvent(new CustomEvent('windowReady'));
        }, 10);
    }
};

document.fonts.onloadingdone = () => {
    loads.fonts = true;

    dispatcher({ type: 'windowIsLoad', data: new Date().getTime() });

    checkLoad();
};

(document.querySelector('html') as HTMLElement).classList.add(`_${process.env.REACT_APP_SYSTEM}`);

window.onload = () => {
    loads.event = true;

    const style = document.querySelector('.initStyle');

    if (style && !seo) {
        style.remove();
    }

    setTimeout(() => {
        dispatcher({ type: 'windowIsLoad', data: true });

        checkLoad();
    }, 10);

    setTimeout(() => {
        loads.fonts = true;

        checkLoad();
    }, 2_000);
};

if (process.env.REACT_APP_SYSTEM === 'app') {
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();

        dispatcher<'app'>({ type: 'isNotApp', data: true });

        setTimeout(() => {
            handlerPopup('appInstructionApplicationPopup', { isShow: true });
        }, 3_000);
    });

    const isInStandaloneMode = () =>
        'standalone' in window.navigator && window.navigator.standalone;

    if (checkIos() && !isInStandaloneMode()) {
        dispatcher<'app'>({ type: 'isNotApp', data: true });

        setTimeout(() => {
            handlerPopup('appInstructionApplicationPopup', { isShow: true });
        }, 3_000);
    }

    if (process.env.REACT_APP_ENV === 'local') {
        dispatcher<'app'>({ type: 'isNotApp', data: true });

        setTimeout(() => {
            handlerPopup('appInstructionApplicationPopup', { isShow: true });
        }, 500);
    }
}

pwaInit();

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    rootElement,
);

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);
// root.render(
//     <Provider store={store}>
//         <Root />
//     </Provider>,
// );
