import React from 'react';

import I from '../types.ts';

const renderField: I['renderField'] = function () {
    const { type, children } = this.props;

    return (
        <>
            {type === 'input' && this.renderInput()}
            {type === 'select' && this.renderSelect()}
            {type === 'date' && this.renderDate()}
            {type === 'checkbox' && this.renderCheckbox()}
            {type === 'file' && this.renderFile()}
            {type === 'text' && this.renderText()}
            {type === 'checkboxes' && this.renderCheckboxes()}
            {type === 'list' && this.renderList()}
            {type === 'switch' && this.renderSwitch()}
            {type === 'files' && this.renderFiles()}
            {type === 'children' && <>{children}</>}
        </>
    );
};

export default renderField;
