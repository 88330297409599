import React from 'react';
import { connect } from 'react-redux';

import CustomHead from '@components/CustomHead';
import SupportCursor from '@components/SupportCursor';
import checkIos from '@functions/checkIos';
import { StoreT } from '@global/types.ts';

import checkAfk from './methods/checkAfk.ts';
import checkPage from './methods/checkPage.ts';
import init from './methods/init.ts';

import RootI from './types.ts';

import Alerts from '../Alerts.jsx';
import renderCursorActions from './renders/renderCursorActions.tsx';
import renderCursorEmodziLists from './renders/renderCursorEmodziLists.tsx';
import renderCursorLists from './renders/renderCursorLists.tsx';
import renderCursorSupport from './renders/renderCursorSupport.tsx';
import renderPopups from './renders/renderPopups.tsx';

const Crm = process.env.REACT_APP_SYSTEM === 'crm' && require('../crm/app/App.tsx').default;
const App = process.env.REACT_APP_SYSTEM === 'app' && require('../app/App.jsx').default;

class Root extends React.Component<RootI['props'], RootI['state']> implements RootI {
    parent: RootI['parent'];

    constructor(props: RootI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    checkPage = checkPage;
    checkAfk = checkAfk;

    renderCursorSupport = renderCursorSupport;
    renderCursorActions = renderCursorActions;
    renderCursorLists = renderCursorLists;
    renderCursorEmodziLists = renderCursorEmodziLists;
    renderPopups = renderPopups;

    init = init;

    componentDidMount(): void {
        this.init();
    }

    render() {
        const { theme, windowIsLoad, windowHeight, windowIsReady, rootInit, designMode } =
            this.props;
        const classes = [
            `_${theme}`,
            `_${process.env.REACT_APP_SYSTEM}`,
            ...(windowIsLoad ? ['_isLoad'] : []),
            ...(checkIos() ? ['_ios'] : []),
            ...(designMode ? [`_design-${designMode}`] : []),
        ];

        return (
            <>
                <div
                    ref={this.parent}
                    className={`body__content _col ${classes.join(' ')}`}
                    onMouseMove={() => this.checkAfk()}
                    key={process.env.REACT_APP_SYSTEM === 'crm' ? '' : ''}
                    style={{
                        minHeight: `${windowHeight}px`,
                    }}
                >
                    <CustomHead
                        title="CRM LIVECARGO"
                        description="Цифровизация вашего бизнеса от найма – до выплат"
                        preview={`${process.env.REACT_APP_DOMEN}${require('../../img/preview.jpg')}`}
                    />
                    {<Alerts />}
                    <SupportCursor />
                    {rootInit && (
                        <>
                            {Crm && <Crm />}
                            {App && <App />}
                            {this.renderPopups()}
                            {this.renderCursorSupport()}
                            {this.renderCursorActions()}
                            {this.renderCursorLists()}
                            {this.renderCursorEmodziLists()}
                        </>
                    )}

                    {!windowIsReady && (
                        <div className="body__hidden">
                            {[1, 2, 3, 4].map((key) => (
                                <p className={`body__hiddenText _${key}`} key={key}>
                                    абвгдеёжзиклмнопрстуфхцшщячьъэюя
                                    АБВГДЕЁЖЗИКЛМНОПРСТУФХЦШЩЯЧЬЪЭЮЯ abcdefghijklmnopqrstuvwxyz
                                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        storePages: state.pages,
        theme: state.theme,
        windowHeight: state.windowHeight,
        rootInit: state.rootInit,
        filterPopup: state.filterPopup,
        alertPopup: state.alertPopup,
        calendarPopup: state.calendarPopup,
        designMode: state.designMode,
    };
}

export default connect(mapStateToProps)(Root);
