import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';
import LazyImage from '@components/lazyImage/lazyImage.tsx';
import ChatExecutorDocI from '@components/popups/chatExecutorDoc/types.ts';

import { RenderColsT } from '../../types.ts';

const renderChatExecutorDocs: RenderColsT<'chatExecutorDocs'> = function ({ name: colName, item }) {
    let content;
    let className;
    const doc = item as { _id: string; title: string; isActive: boolean; src?: string };

    if (colName === 'name') {
        const { otherData } = this.props;

        const setActive = otherData?.setActive as (
            data: Parameters<ChatExecutorDocI['setActive']>[0],
        ) => Promise<void>;

        content = (
            <>
                <label className="v2table__checkbox _CLICK _ROW _vertical">
                    <div className="v2table__checkboxBox">
                        <Checkbox
                            name={doc._id}
                            value={!!doc.isActive}
                            onChange={() => {
                                setActive({ id: doc._id });
                            }}
                        />
                    </div>

                    <div className="v2table__text _block">{doc.title}</div>
                </label>
            </>
        );
    }

    if (colName === 'preview') {
        content = (
            <>
                {doc.src ? (
                    <>
                        <div className="v2table__preview">
                            <LazyImage src={doc.src} cover={true} />
                        </div>
                    </>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderChatExecutorDocs;
