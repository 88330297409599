import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../functions/handlerPopup';

import Animate from './Animate.jsx';
import Icon from './icon/Icon.tsx';

class ServerNotAvailable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', user, isHide } = this.props;
        const isServerNotAvailable = !!user?.isServerNotAvailable && !isHide;

        return (
            <Animate
                className={`body__serverNotAvailable ${className}`}
                isShow={isServerNotAvailable}
            >
                <div
                    className="serverNotAvailable _col"
                    onClick={() => {
                        if (process.env.REACT_APP_SYSTEM === 'app') {
                            handlerPopup('serverNotAvailablePopup', {
                                isShow: true,
                            });
                        }
                    }}
                >
                    <div className="serverNotAvailable__inner _row">
                        <i className="serverNotAvailable__icon">
                            <Icon name="alert-2" />
                        </i>
                        <div className="serverNotAvailable__title">Идут технические работы</div>
                        {process.env.REACT_APP_SYSTEM === 'crm' && (
                            <div
                                className="serverNotAvailable__btn _click"
                                onClick={() => {
                                    handlerPopup('alertPopup', {
                                        isShow: true,
                                        type: 'serverNotAvailable',
                                    });
                                }}
                            >
                                Подробнее
                            </div>
                        )}
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ServerNotAvailable);

ServerNotAvailable.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    isHide: PropTypes.bool,
};
