import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@components/avatar/Avatar';
import Icon from '@components/icon/Icon';
import Link from '@components/link/Link';
import { dispatcher } from '@redux/redux';
import uploadFile from '@requests/uploadFile';

import Animate from '../../../components/Animate.jsx';
import Button from '../../../components/Button.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import Loader from '../../../components/Loader.jsx';
import Contract from '../../../components/app/Contract.jsx';

import getCurrentCorporation from '../../../functions/app/getCurrentCorporation';
import getHeaders from '../../../functions/getHeaders';
import getUserInfo from '../../../functions/getUserInfo';

const selects = require('../../../infos/selects.json');

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderAvatar = this.renderAvatar.bind(this);
        this.renderAvatarInfo = this.renderAvatarInfo.bind(this);
        this.changeAvatar = this.changeAvatar.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.uploadAvatar = this.uploadAvatar.bind(this);
    }

    avatarItems = ['image', 'empty'];

    getAvatarItems() {
        const { user } = this.props;

        return user.logo?.fullSrc ? [{ key: 'image', src: user.logo.fullSrc }] : [{ key: 'empty' }];
    }

    renderAvatar({ item, prop }) {
        return (
            <div className="settings__avatarPreviewItem _col">
                {prop === 'image' && (
                    <>
                        <label className="settings__avatarPreviewImage">
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.uploadAvatar}
                                accept="image/*"
                            />
                            <Avatar className="settings__avatarPreviewImageItem" image={item.src} />
                        </label>
                    </>
                )}
                {prop === 'empty' && (
                    <>
                        <i className="settings__avatarPreviewIcon">
                            <Icon name="app-settings-profile" />
                        </i>
                    </>
                )}
            </div>
        );
    }

    async uploadAvatar(e) {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            const [file] = e.target.files;

            const formData = new FormData();

            e.target.value = null;

            formData.set('image', file);

            const { src } = await uploadFile({ formData });

            this.changeAvatar(src);
        }
    }

    changeAvatar(src) {
        const { loadingKey } = this.state;

        if (!loadingKey) {
            this.handlerLoading('avatar').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/executor-app`,
                        { action: 'change-logo', src },
                        {
                            headers: getHeaders(),
                        },
                    )
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                const { logo } = res.data.data;

                                dispatcher({
                                    type: 'user',
                                    data: JSON.parse(JSON.stringify({ ...this.props.user, logo })),
                                });
                            }

                            this.handlerLoading(null);
                        },
                        () => null,
                    );
            });
        }
    }

    renderAvatarInfo({ prop }) {
        return (
            <div className="settings__infoAvatarItem _col">
                {prop === 'empty' && (
                    <>
                        <p className="settings__infoDescription _row">Фото не установлено</p>
                        <label className="settings__infoLink">
                            <input
                                type="file"
                                className="settings__infoLinkInput"
                                onChange={this.uploadAvatar}
                                accept="image/*"
                            />
                            Установить фото
                        </label>
                    </>
                )}
                {prop === 'image' && (
                    <>
                        <p className="settings__infoDescription _row">Фото установлено</p>
                        <div
                            className="settings__infoLink _alert"
                            onClick={() => {
                                this.changeAvatar(null);
                            }}
                        >
                            Удалить фото
                        </div>
                    </>
                )}
            </div>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    changeUser({ detail: { initiatorUserId, fields } }) {
        const { user } = this.props;

        if (initiatorUserId === user._id && fields.avatar) {
            this.handlerLoading(null);
        }
    }

    componentDidMount() {
        document.addEventListener('changeUser', this.changeUser);
    }

    componentWillUnmount() {
        document.removeEventListener('changeUser', this.changeUser);
    }

    render() {
        const { loadingKey } = this.state;
        const { user } = this.props;

        const organization = selects.executorOrganizations.items.find(
            (item) => item.key === user.organization,
        )?.content;
        const corporation = getCurrentCorporation({ user });
        const { contract } = user;

        return (
            <div className="settings _col">
                <div className="settings__blocks">
                    <div
                        className={`settings__avatar _row ${
                            loadingKey === 'avatar' ? '_isLoading' : ''
                        }`}
                    >
                        <div className="settings__avatarPreview">
                            <Animate
                                className="settings__avatarLoader _loader"
                                isShow={loadingKey === 'avatar'}
                            >
                                <div className="settings__avatarLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <ListAbsoluteMain
                                className="settings__avatarPreviewInner"
                                items={this.getAvatarItems()}
                                renderItem={this.renderAvatar}
                                classNameItem="settings__avatarPreviewItem"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={[]}
                                isNotParamsItem={true}
                                propsForUpdate={['path']}
                            />
                        </div>

                        <div className="settings__avatarContent">
                            <div className="settings__info _inner">
                                <div className="settings__infoTitle">Ваше фото:</div>
                                <ListAbsoluteMain
                                    className="settings__infoAvatar"
                                    items={this.getAvatarItems()}
                                    renderItem={this.renderAvatarInfo}
                                    classNameItem="settings__infoAvatarItem"
                                    prop="key"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    currentItemKey={this.getAvatarItems()[0].key}
                                    allItems={this.avatarItems}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings__info">
                        <div className="settings__infoTitle">Ваши данные:</div>
                        <div className="settings__infoDescription _row">
                            <span>ФИО:</span>
                            <div className="settings__infoDescriptionItem">
                                {getUserInfo({ user, type: 'name' })}
                            </div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Телефон:</span>
                            <div className="settings__infoDescriptionItem">{user.phone}</div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Форма работы:</span>
                            <div className="settings__infoDescriptionItem">{user.typeText}</div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>Тип:</span>
                            <div className="settings__infoDescriptionItem">{organization}</div>
                        </div>
                        <div className="settings__infoDescription _row">
                            <span>ИНН:</span>
                            <div className="settings__infoDescriptionItem">{user.inn || '–'}</div>
                        </div>
                    </div>
                    {corporation && corporation.id !== 'admin' && (
                        <div className="settings__info">
                            <div className="settings__infoTitle">Данные компании:</div>
                            <div className="settings__infoDescription _row">
                                <span>Название:</span>
                                <div className="settings__infoDescriptionItem">
                                    {corporation.name}
                                </div>
                            </div>
                            <div className="settings__infoDescription _row">
                                <span>ИНН:</span>
                                <div className="settings__infoDescriptionItem">
                                    {corporation.inn}
                                </div>
                            </div>
                            {contract && (
                                <div className="settings__infoContract">
                                    <Contract contract={contract} />
                                </div>
                            )}

                            {user.corporations.filter((item) => item.id !== 'admin').length > 1 && (
                                <Link className="settings__infoButton" href="corporations-list">
                                    <Button className="_blue">Сменить компанию</Button>
                                </Link>
                            )}
                        </div>
                    )}
                </div>

                <div className="settings__link">
                    <Link className="link _click _alert" href="chat-index">
                        Сообщить об ошибке
                    </Link>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Profile);

Profile.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
};
