import { MouseEvent } from 'react';

import { CursorEmodziListT, CustomListenerT, ListenerT } from '@global/types';
import { dispatcher, store } from '@redux/redux';

type SettingsT = {
    targetClassName: string;
    className?: string;
    preventDefault?: boolean;
    stopPropagation?: boolean;
};

const deleteEmodziList = function ({ id }: { id: CursorEmodziListT['_id'] }): void {
    const emodziList = store.getState().cursorEmodziList.map((item) => ({ ...item }));

    const index = emodziList.findIndex((item) => item._id === id);

    if (index !== -1) {
        emodziList.splice(index, 1);

        dispatcher({ type: 'cursorEmodziList', data: emodziList });

        const observerIndex = observers.findIndex((item) => item.id === id);

        if (observerIndex !== -1) {
            observers[observerIndex].observer.disconnect();

            (document.removeEventListener as ListenerT<MouseEvent>)(
                'click',
                observers[observerIndex].documentClickHandler,
            );
            (document.removeEventListener as CustomListenerT)(
                'cursorEmodziListClick',
                observers[observerIndex].documentClickHandler,
            );
            document.removeEventListener(
                'changeWidthWindow',
                observers[observerIndex].documentResizeHandler,
            );

            if (process.env.REACT_APP_SYSTEM === 'app') {
                (document.removeEventListener as CustomListenerT)(
                    'touchstart',
                    observers[observerIndex].documentClickHandler,
                );
            } else {
                window.removeEventListener(
                    'scroll',
                    observers[observerIndex].documentScrollHandler,
                );
            }

            observers.splice(observerIndex, 1);
        }
    }
};

const getSizeItem = function (): {
    width: number;
    height: number;
} {
    const div = document.createElement('div');

    div.classList.add('v2cursorEmodziList__item');

    div.style.position = 'absolute';
    div.style.zIndex = '-1';
    div.style.top = '0';
    div.style.left = '0';
    div.style.opacity = '0';
    div.style.pointerEvents = 'none';

    document.body.appendChild(div);

    const width = div.offsetWidth;
    const height = div.offsetHeight;

    div.remove();

    return { width, height };
};

const getPosition = function ({
    target,
    list,
    settings,
}: {
    target: HTMLElement;
    list: CursorEmodziListT;
    settings: SettingsT;
}): { left: number; top: number; dir: CursorEmodziListT['dir'] } {
    const targetLeft = target.getBoundingClientRect().x;
    const targetTop = target.getBoundingClientRect().y;
    const { width, height } = getSizeItem();
    const margin = 16;
    let leftDelta = 0;
    let topDelta = 0;
    let dir = list.dir;

    if (list.dir === 'top') {
        leftDelta = 0;
        topDelta = -target.offsetHeight / 2 - height / 2 - margin;
    }

    if (list.dir === 'bottom') {
        leftDelta = 0;
        topDelta = target.offsetHeight / 2 + height / 2 + margin;
    }

    let resultLeft = targetLeft + target.offsetWidth / 2 - width / 2 + leftDelta;
    let resultTop = targetTop + target.offsetHeight / 2 - height / 2 + topDelta;

    if (resultTop + height > document.documentElement.clientHeight - 20) {
        const reversePosition = getPosition({
            target,
            list: { ...list, dir: 'top' },
            settings,
        });

        dir = 'top';

        resultLeft = reversePosition.left;
        resultTop = reversePosition.top;
    }

    return { left: resultLeft, top: resultTop, dir };
};

const observers: {
    id: string;
    observer: MutationObserver;
    documentClickHandler: (e: MouseEvent | CustomEvent<{ e: MouseEvent }>) => void;
    documentResizeHandler: () => void;
    documentScrollHandler: (e: Event) => void;
}[] = [];

const addEmodziList = function ({
    e,
    list,
    settings,
}: {
    e: Pick<MouseEvent, 'target'>;
    list: CursorEmodziListT;
    settings: SettingsT;
}): void {
    const emodziList = store.getState().cursorEmodziList.map((item) => ({ ...item }));
    const target = (e.target as HTMLElement).closest(settings.targetClassName) as HTMLElement;
    const oldItem = emodziList.find((item) => item._id === list._id);

    if (target && !oldItem) {
        const { left, top, dir } = getPosition({ target, list, settings });

        emodziList.push({
            ...list,
            position: { left, top },
            dir,
            className: settings.className,
        });

        const observer = new MutationObserver((ev: MutationRecord[]) => {
            if (ev[0].removedNodes) {
                const node = ev[0].removedNodes[0];

                if (node === target) {
                    observer.disconnect();

                    deleteEmodziList({ id: list._id });
                }
            }
        });

        const documentClickHandler = (
            ev: MouseEvent | TouchEvent | CustomEvent<{ e: MouseEvent | TouchEvent }>,
        ) => {
            const node = document.querySelector(
                `.v2cursorEmodziList__item[data-_id="${list._id}"]`,
            );

            const eventTarget = (
                typeof ev.detail === 'object' ? ev.detail.e.target : ev.target
            ) as HTMLElement;

            if (
                node &&
                target &&
                eventTarget !== target &&
                !target.contains(eventTarget) &&
                eventTarget !== node &&
                !node.contains(eventTarget)
            ) {
                deleteEmodziList({ id: list._id });
            }
        };

        const documentResizeHandler = () => {
            deleteEmodziList({ id: list._id });
        };

        const documentScrollHandler = (scrollE: Event) => {
            const listParent = document.querySelector(
                `.v2cursorEmodziList__item[data-_id="${list._id}"]`,
            ) as HTMLElement;

            if (!listParent?.contains(scrollE.target as HTMLElement)) {
                deleteEmodziList({ id: list._id });
            }
        };

        observer.observe(target.parentNode!, { childList: true });

        observers.push({
            id: list._id,
            observer,
            documentClickHandler,
            documentResizeHandler,
            documentScrollHandler,
        });

        (document.addEventListener as CustomListenerT)(
            'cursorEmodziListClick',
            documentClickHandler,
        );
        (document.addEventListener as ListenerT<MouseEvent>)('click', documentClickHandler);
        document.addEventListener('changeWidthWindow', documentResizeHandler);

        if (process.env.REACT_APP_SYSTEM === 'app') {
            (document.addEventListener as ListenerT<TouchEvent>)(
                'touchstart',
                documentClickHandler,
            );
        } else {
            window.addEventListener('scroll', documentScrollHandler, true);
        }

        dispatcher({ type: 'cursorEmodziList', data: emodziList });
    }
};

const setEmodziList = function (
    list: Omit<CursorEmodziListT, 'className' | 'callback'> & {
        getInput: () => HTMLInputElement;
        getValue: () => string;
        onChange: (value: string) => Promise<void>;
    },
    settings: SettingsT,
): {
    onClick: (e: Pick<MouseEvent, 'target' | 'preventDefault' | 'stopPropagation'>) => void;
} {
    const props = {
        onClick: (e: Pick<MouseEvent, 'target' | 'preventDefault' | 'stopPropagation'>) => {
            if (settings.preventDefault) {
                e.preventDefault();
            }
            if (settings.stopPropagation) {
                document.dispatchEvent(new CustomEvent('cursorEmodziListClick', { detail: { e } }));

                e.stopPropagation();
            }

            const cursorEmodziList = store.getState().cursorEmodziList;

            if (cursorEmodziList.find((item) => item._id === list._id)) {
                deleteEmodziList({ id: list._id });
            } else {
                const { getInput, getValue, onChange, ...listData } = list;
                const callback = async (emodzi: string) => {
                    const input = getInput();
                    const thisValue = getValue();

                    if (input) {
                        const currentPosition = input.selectionEnd || thisValue?.length || 0;
                        let resultValue = emodzi;

                        if (currentPosition > 0) {
                            resultValue = thisValue.slice(0, currentPosition);

                            resultValue += emodzi;

                            resultValue += thisValue.slice(currentPosition);
                        }

                        input.focus();
                        input.selectionEnd = currentPosition + emodzi.length;

                        await onChange(resultValue);

                        input.focus();
                        input.selectionEnd = currentPosition + emodzi.length;
                    }
                };

                addEmodziList({ e, list: { ...listData, callback }, settings });
            }
        },
    };

    return props;
};

export { addEmodziList, deleteEmodziList, setEmodziList };
