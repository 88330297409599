import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Icon from './icon/Icon.tsx';
import Loader from './Loader.jsx';

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderIcon = this.renderIcon.bind(this);
    }

    renderIcon() {
        const { icon } = this.props;

        return (
            <div className={`button__icon _${icon.type} _${icon.name}`}>
                <Icon name={icon.name} />
            </div>
        );
    }

    render() {
        const {
            children,
            className,
            icon,
            showLoader,
            onClick,
            isDisabled = false,
            ...props
        } = this.props;

        return (
            <div
                className={`button _row _click ${icon ? `_withIcon _${icon.type}` : ''} ${className || ``} ${
                    showLoader ? '_showLoader' : ''
                } ${!children ? '_onlyIcon' : ''} ${isDisabled ? '_disabled' : ''}`}
                onClick={(e) => {
                    if (!showLoader && onClick && typeof onClick === 'function' && !isDisabled) {
                        onClick(e);
                    }
                }}
                {...props}
            >
                <div
                    className="button__loader _loader"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className="button__loaderItem _loaderItem">
                        <Loader />
                    </div>
                </div>
                {icon && icon.type === 'start' && this.renderIcon()}
                {children && <div className="button__content">{children}</div>}
                {icon && icon.type === 'end' && this.renderIcon()}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Button);

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.object,
    showLoader: PropTypes.bool,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};
