import React from 'react';

import ExportI from '@components/popups/export/types.ts';
import Switch from '@components/switch/Switch.tsx';

import { RenderColsT } from '../../types.ts';

const renderExportItems: RenderColsT<'exportItems'> = function ({ name: colName, item }) {
    const { loadings } = this.state;
    let content;
    let className;
    const exportItem = item as { _id: string; content: string; isActive: boolean };

    if (colName === 'name') {
        content = <>{exportItem.content}</>;
    }

    if (colName === 'action') {
        const { otherData } = this.props;

        const setActive = otherData?.setActive as (
            data: Parameters<ExportI['setActive']>[0],
        ) => Promise<void>;

        content = (
            <>
                <Switch
                    className="_mediumSize _green"
                    value={exportItem.isActive}
                    name="isActive"
                    disabled={!!loadings[exportItem._id]}
                    onChange={async () => {
                        await this.loadingHandler({ name: exportItem._id, loading: true });

                        try {
                            await setActive({
                                name: exportItem._id,
                                isActive: !exportItem.isActive,
                            });
                        } catch (error) {}

                        await this.loadingHandler({ name: exportItem._id, loading: undefined });
                    }}
                />
            </>
        );
    }

    return { content, className };
};

export default renderExportItems;
