import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import CustomHead from '../../components/CustomHead.jsx';
import Pages from '../../components/Pages.jsx';
import ServerNotAvailable from '../../components/ServerNotAvailable.jsx';
import AppGalery from '../../components/app/Galery.jsx';
import StartPopup from '../../components/app/Start.jsx';
import InfoPopupWrapper from '../../components/app/infoPopup/InfoPopupWrapper.jsx';
import PopupWrapper from '../../components/app/popups/Wrapper.jsx';

import checkAuth from '../../functions/app/checkAuth';
import handlerPopup from '../../functions/app/handlerPopup';
import logoutActions from '../../functions/app/logoutActions';
import connectSockets from '../../functions/connectSockets';
import setTheme from '../../functions/setTheme';

import { dispatcher } from '../../redux/redux';
import '../../scss/app/main.scss';
import Join from '../crm/join/Join.tsx';
import Auth from './Auth.jsx';
import Balance from './Balance.jsx';
import Chat from './Chat.jsx';
import Corporations from './Corporations.jsx';
import CorporationsPopup from './CorporationsPopup.jsx';
import Index from './Index.jsx';
import Notifications from './Notifications.jsx';
import Pays from './Pays.jsx';
import Settings from './Settings.jsx';
import Start from './Start.jsx';

// import Corporations from './Corporations.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        if (localStorage.getItem('isAppReload')) {
            this.isAppReload = true;

            localStorage.removeItem('isAppReload');

            dispatcher({ type: 'isHideStartPopup', data: true }).then(() => {
                setTheme();
            });
        }

        this.handlerSocket = this.handlerSocket.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
    }

    pages = {
        start: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Start />
                    </div>
                );
            },
        },
        auth: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Auth />
                    </div>
                );
            },
        },
        index: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Index />
                    </div>
                );
            },
        },
        balance: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Balance />
                    </div>
                );
            },
        },
        settings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Settings />
                    </div>
                );
            },
        },
        chat: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Chat />
                    </div>
                );
            },
        },
        pays: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Pays />
                    </div>
                );
            },
        },
        notifications: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Notifications />
                    </div>
                );
            },
        },
        corporations: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Corporations />
                    </div>
                );
            },
        },
        'corporations-list': {
            render() {
                return (
                    <div className="body__pageInner">
                        <CorporationsPopup />
                    </div>
                );
            },
        },
        join: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Join />
                    </div>
                );
            },
        },
    };

    changeCorporation() {
        this.isInitCheck = false;
    }

    visibilityDocChange({ detail: { isActive } }) {
        const { user, socket } = this.props;

        if (isActive) {
            if (user && socket && !socket.connected) {
                connectSockets(true);
            }

            if (user) {
                checkAuth(false).then(
                    () => null,
                    () => null,
                );
            }
        }
    }

    handlerSocket({ detail }) {
        const { user } = this.props;
        const { name, data } = detail;

        if (name === 'appExecutor') {
            const { fields } = data;

            dispatcher({ type: 'user', data: { ...user, ...fields } }).then(() => {
                document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
            });
        }

        if (name === 'system') {
            const { version } = data;

            if (version !== +process.env.REACT_APP_APP_VERSION) {
                dispatcher({ type: 'user', data: { ...user, systemVersion: version } }).then(() => {
                    handlerPopup({ name: 'appNewVersionPopup', isShow: true });
                });
            }
        }

        if (name === 'executor' && data?.type === 'logout') {
            logoutActions();
            handlerPopup({
                name: 'appLogoutPopup',
                isShow: true,
            });
        }

        if (name === 'joinContracts') {
            const { id, fields } = data;

            if (user.contract?._id === id) {
                const updatedContract = JSON.parse(JSON.stringify(user.contract));

                Object.keys(fields).forEach((key) => {
                    updatedContract[key] = fields[key];
                });

                dispatcher({ type: 'user', data: { ...user, contract: updatedContract } }).then(
                    () => {
                        document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
                    },
                );
            }
        }

        if (name === 'pays') {
            const { fields } = data;

            if (fields?.isUpdatePays || fields?.item?.status === 'completed') {
                checkAuth(false).then(
                    () => null,
                    () => null,
                );
            }
        }
    }

    componentDidMount() {
        const { user } = this.props;

        if (user) {
            checkAuth().then(
                () => null,
                () => null,
            );
        }

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        window.addEventListener('online', () => {
            handlerPopup({ name: 'appNetworkConnectPopup', isShow: false });

            document.dispatchEvent(
                new CustomEvent('networkConnect', { detail: { isActive: true } }),
            );
        });

        window.addEventListener('offline', () => {
            handlerPopup({ name: 'appNetworkConnectPopup', isShow: true });

            document.dispatchEvent(
                new CustomEvent('networkConnect', { detail: { isActive: false } }),
            );
        });
    }

    render() {
        const { pagesStore } = this.props;
        const isOpenOrderChat = !!pagesStore['order-chat']?.state?.isShow;

        return (
            <>
                {process.env.REACT_APP_ENV !== 'local' && !this.isAppReload && <StartPopup />}
                <InfoPopupWrapper />
                <CustomHead title="Livecargo App" description="Служба доставки для бизнеса">
                    <link rel="icon" href={require('../../img/app/favicon.ico')} />
                    <link
                        rel="apple-touch-icon"
                        type="image/png"
                        href={require(
                            `../../pwa/${
                                process.env.REACT_APP_ENV === 'dev'
                                    ? 'app-dev-icon.png'
                                    : 'app-icon.png'
                            }`,
                        )}
                    />
                </CustomHead>
                <PopupWrapper name="authCodePopup" />
                <PopupWrapper name="appStartPopup" />
                <PopupWrapper name="appBalancePopup" />
                <PopupWrapper name="appLogoutPopup" />
                <PopupWrapper name="appNetworkConnectPopup" />
                <PopupWrapper name="appInstructionApplicationPopup" />
                <PopupWrapper name="appNewVersionPopup" />
                <PopupWrapper name="appNotificationsPopup" />
                <PopupWrapper name="appSignContractPopup" />
                <PopupWrapper name="appSignPayPopup" />
                <PopupWrapper name="appWalletPopup" />
                <PopupWrapper name="appWebPushPopup" />
                <PopupWrapper name="appFnsActivateInfoPopup" />
                <PopupWrapper name="appPayInfoPopup" />
                <PopupWrapper name="appPayCancelPopup" />
                <PopupWrapper name="appSmzInstructionPopup" />
                <PopupWrapper name="appJoinCorporationPopup" />
                <PopupWrapper name="appContractPopup" />
                <PopupWrapper name="appTaxAboutPopup" />
                <PopupWrapper name="appTaxAmountPopup" />

                <ServerNotAvailable />

                <PopupWrapper name="serverNotAvailablePopup" />

                <AppGalery />

                <Pages
                    classNamePage={`body__page _col _app ${
                        isOpenOrderChat ? '_openOrderChat' : ''
                    }`}
                    filter={(page) => !page.level}
                    pages={this.pages}
                    context={this}
                    fullHeight={true}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        pagesStore: state.pages,
        socket: state.socket,
        isHideStartPopup: state.isHideStartPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    user: PropTypes.object,
    pagesStore: PropTypes.object,
    socket: PropTypes.object,
    isHideStartPopup: PropTypes.bool,
};
