import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Icon from '@components/icon/Icon';

import Animate from '../../../components/Animate.jsx';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import Button from '../../../components/Button.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import ListScroll from '../../../components/ListScroll.jsx';
import Loader from '../../../components/Loader.jsx';
import Table from '../../../components/crm/manual/Table.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import handlerTax from '../../../functions/app/handlerTax';
import getFormatPrice from '../../../functions/getFormatPrice';
import getFormatedDate, { namesMonths } from '../../../functions/getFormatedDate';
import getHeaders from '../../../functions/getHeaders';

import getTaxes from '../../../requests/getTaxes';

class Tax extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderAction = this.renderAction.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 10;

    allActions = ['add', 'check'];

    getCurrentAction() {
        const { user } = this.props;
        let currentAction;

        if (user.fnsTax === 'active' || user.isJurStatusActive !== true) {
            currentAction = null;
        } else if (user.fnsTax === 'check') {
            currentAction = 'check';
        } else {
            currentAction = 'add';
        }

        if (user.fnsTax === 'inactive-process') {
            currentAction = null;
        }

        if (user.fnsTax === 'inactive') {
            currentAction = 'inactive';
        }

        return currentAction;
    }

    getActions() {
        const currentAction = this.getCurrentAction();

        return currentAction ? [{ key: currentAction }] : [];
    }

    renderAction({ prop: key }) {
        const { loadingKey, taxError } = this.state;
        const { user } = this.props;

        return (
            <div className={`settingsTax__action _${key}`}>
                <div className="settingsTax__actionInner _col">
                    {key === 'add' && (
                        <>
                            <div className="settingsTax__actionIcon">
                                <Icon name="app-settings-fns-tax" />
                            </div>
                            <div className="settingsTax__actionTitle">
                                Подключите
                                <br />
                                налоговую копилку
                            </div>
                            <p className="settingsTax__actionDescription">
                                Подключите налоговую копилку, чтобы мы могли получать и оплачивать
                                за вас налоговые начисления.
                            </p>
                            <div className="settingsTax__actionButton">
                                <Button
                                    className="_notShadow"
                                    onClick={handlerTax.bind(this, key)}
                                    showLoader={loadingKey === key}
                                >
                                    Подключить копилку
                                </Button>
                            </div>
                            <div className="settingsTax__actionButton">
                                <Button
                                    className="_weightNormal _blue"
                                    onClick={() => {
                                        handlerPopup({ name: 'appTaxAboutPopup', isShow: true });
                                    }}
                                >
                                    Что такое налоговая копилка?
                                </Button>
                            </div>
                        </>
                    )}
                    {key === 'inactive' && (
                        <>
                            <div className="settingsTax__actionIcon">
                                <Icon name="app-settings-fns-tax" />
                            </div>
                            <div className="settingsTax__actionTitle">
                                Налоговая копилка отключена
                            </div>
                            <p className="settingsTax__actionDescription">
                                Все средства в размере {getFormatPrice(user.fnsRemoveTaxAmount)} ₽
                                были выплачены по вашим реквизитам
                            </p>
                        </>
                    )}
                    {key === 'check' && (
                        <>
                            <div className="settingsTax__actionIcon">
                                <Icon name="alert" />
                            </div>
                            <div className="settingsTax__actionTitle">Подтвердите разрешение</div>
                            <p className="settingsTax__actionDescription">
                                Чтобы мы могли получать и оплачивать за вас налоговые начисления —
                                дайте нам разрешение
                            </p>
                            <AnimateChangeUp
                                className="settingsTax__actionError"
                                renderKey={taxError || undefined}
                                parentStyles={['height']}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        this.setState({ actionsRenderKey: new Date().getTime() });
                                    }
                                }}
                            >
                                {taxError === 'not-confirm' ? (
                                    <>Необходимо подтвердить права в&nbsp;приложении «Мой налог»</>
                                ) : null}
                            </AnimateChangeUp>
                            <div className="settingsTax__actionButton">
                                <Button
                                    className="_notShadow"
                                    onClick={handlerTax.bind(this, key)}
                                    showLoader={loadingKey === key}
                                >
                                    Подтвердить разрешение
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    getTaxInfo() {
        axios
            .get(`${process.env.REACT_APP_API}/executor-tax?type=info`, { headers: getHeaders() })
            .then((res) => {
                const { success, data } = res.data;

                if (success) {
                    const { taxInfo } = data;

                    this.setState({ taxInfo });
                }
            });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getTaxes(query).then(
                ({ taxes, isLimit, counter }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(taxes, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    renderCard({ item }) {
        return (
            <div className="settingsTax__historyCard">
                <div className="settingsTax__historyCardHead _row">
                    <div
                        className={`settingsTax__historyCardAmount ${
                            item.amount > 0 ? '_positive' : ''
                        }`}
                    >
                        {item.amount > 0 ? '+' : '-'}
                        {getFormatPrice(Math.abs(item.amount))} ₽
                    </div>
                    <div className="settingsTax__historyCardDate">
                        {getFormatedDate({
                            date: new Date(item.createdDate),
                            type: 'full',
                            isShortYear: true,
                        })}
                    </div>
                </div>
                <div className="settingsTax__historyCardContent">
                    <div className="settingsTax__historyCardTitle">{item.title}</div>
                    <p className="settingsTax__historyCardDescription">{item.content}</p>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getTaxInfo();
        this.getItems();
    }

    render() {
        const currentAction = this.getCurrentAction();
        const {
            taxInfo,
            actionsRenderKey,
            items = [],
            isDisabledScroll = false,
            isLoadingFilter = false,
            isLimit,
            counter = 0,
            isInit,
        } = this.state;
        const { user, handlerLoaderList } = this.props;
        const debt = user.fnsAccrialsAndDebts?.debt || 0;
        const prevTaxDescription = `За ${namesMonths[taxInfo?.month]?.[0].toLowerCase()}`;

        return (
            <div ref={this.parent} className="settings _col">
                <div
                    className={`settingsTax ${
                        currentAction || user.isJurStatusActive !== true ? '_disabled' : ''
                    }`}
                >
                    <ListAbsoluteMain
                        className="settingsTax__actions"
                        items={this.getActions()}
                        renderItem={this.renderAction}
                        classNameItem="settingsTax__action"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        currentItemKey={currentAction}
                        allItems={this.allActions}
                        keyRender={actionsRenderKey}
                    />
                    <div className={`settingsTax__info _row ${taxInfo ? '_ready' : ''}`}>
                        <Animate className="settingsTax__infoLoader _loader" isShow={!taxInfo}>
                            <div className="settingsTax__infoLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="settingsTax__infoBlock _col _full">
                            <div className="settingsTax__infoBlockTitle">
                                {getFormatPrice(taxInfo?.amount)} ₽
                            </div>
                            <p className="settingsTax__infoBlockDescription">Отложено на оплату</p>
                            <div
                                className="settingsTax__infoBlockButton _row _click"
                                onClick={() => {
                                    handlerPopup({ name: 'appTaxAboutPopup', isShow: true });
                                }}
                            >
                                <i className="settingsTax__infoBlockButtonIcon">
                                    <Icon name="info" />
                                </i>
                                Узнайте, как работает копилка
                            </div>
                        </div>
                        <div
                            className="settingsTax__infoBlock _col"
                            onClick={() => {
                                handlerPopup({
                                    name: 'appTaxAmountPopup',
                                    isShow: true,
                                    amount: taxInfo?.prevAmount,
                                    description: prevTaxDescription,
                                });
                            }}
                        >
                            <i className="settingsTax__infoBlockInfo _click">
                                <Icon name="info" />
                            </i>
                            <div className="settingsTax__infoBlockTitle">
                                {getFormatPrice(taxInfo?.prevAmount)} ₽
                            </div>
                            <p className="settingsTax__infoBlockDescription">
                                {prevTaxDescription}
                            </p>
                        </div>
                        <div className={`settingsTax__infoBlock _col ${debt > 0 ? '_alert' : ''}`}>
                            <div className="settingsTax__infoBlockTitle">
                                {getFormatPrice(debt)} ₽
                            </div>
                            <p className="settingsTax__infoBlockDescription">Задолженность</p>
                        </div>
                    </div>
                    <div className="settingsTax__history">
                        <div className="settingsTax__historyHead _row">
                            <div className="settingsTax__historyTitle">
                                Операции:{' '}
                                <AnimateChangeUp
                                    className="settingsTax__historyTitleItem"
                                    renderKey={counter}
                                >
                                    {counter}
                                </AnimateChangeUp>
                            </div>
                        </div>
                        <div className={`settingsTax__historyContent ${isInit ? '_ready' : ''}`}>
                            <Animate
                                className="settingsTax__historyContentLoader _loaderScroll"
                                isShow={!isInit}
                            >
                                <div className="settingsTax__historyContentLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>

                            <div className="settingsTax__historyContentInner">
                                {items.length === 0 && (
                                    <div className="settingsTax__historyEmpty">
                                        <div className="empty _col _notBack _block">
                                            <div className="empty__inner">
                                                <div className="empty__title">
                                                    У вас нет накоплений
                                                </div>
                                                <p className="empty__content">
                                                    История по ним будет
                                                    <br />
                                                    отображаться здесь
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="settingsTax__historyCards">
                                    <ListScroll
                                        getParent={() =>
                                            this.parent.current?.closest(
                                                '.headPage__innerBoxContent',
                                            )
                                        }
                                        callback={this.getMoreItems}
                                        startCounter={this.stepCounter}
                                        stepCounter={this.stepCounter}
                                        maxCounter={Infinity}
                                        lengthCurrent={items.length}
                                        keyUpdate={items.length}
                                        isDisabledScroll={isDisabledScroll || isLoadingFilter}
                                        isLimit={isLimit}
                                        handlerLoaderList={handlerLoaderList}
                                    >
                                        {items.map((item, key) => this.renderCard({ item, key }))}
                                    </ListScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        windowIsReady: state.windowIsReady,
        pagesStore: state.pages,
    };
}

export default connect(mapStateToProps)(Tax);

Tax.propTypes = {
    user: PropTypes.object,
    windowIsReady: PropTypes.bool,
    pagesStore: PropTypes.object,
    handlerLoaderList: PropTypes.func,
};
