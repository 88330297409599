import PropTypes from 'prop-types';

import React from 'react';

import getQueryFilter from '../../../functions/getQueryFilter';
import getRealParams from '../../../functions/getRealParams.ts';
import removeTransition from '../../../functions/removeTransition.ts';

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getMoreItems = this.getMoreItems.bind(this);
        this.getCounterItems = this.getCounterItems.bind(this);
        this.handlerUpdateFilter = this.handlerUpdateFilter.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.setHeightPage = this.setHeightPage.bind(this);
        this.getQueryForRequest = this.getQueryForRequest.bind(this);
        this.setItems = this.setItems.bind(this);
        this.getParentForScroll = this.getParentForScroll.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.setFilter = this.setFilter.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 25;

    setHeightPage() {
        const { setHeightPage } = this.props;

        if (this.parent.current) {
            const page = this.parent.current;

            const { height: heightPage } = getRealParams({
                parent: page,
                elem: '.widget__content',
                width: page.offsetWidth,
            });

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage }, setHeightPage);
            }
        }
    }

    getMoreItems(counter, isStart) {
        const actionPrev = () =>
            new Promise((resolve) => {
                this.setState({ isDisabledScroll: true }, () => {
                    if (isStart) {
                        resolve();
                    } else {
                        if (this.controller) {
                            this.controller.abort();
                        }

                        this.controller = new AbortController();

                        this.getItems().then(resolve, () => null);
                    }
                });
            });

        if (counter < 0) {
            counter = 0;
        }

        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                actionPrev().then(() => {
                    removeTransition({
                        item: this.classNameItem || '.tableInfo__row',
                        isCurrent: true,
                    });

                    setTimeout(() => {
                        this.setState({ isDisabledScroll: false }, resolve);
                    }, 300);
                });
            });
        });
    }

    getQueryForRequest() {
        const { counterScroll } = this.state;
        const getFilterModel = this.props.getFilterModel || this.getFilterModel;
        const filter = getFilterModel?.() || this.props.filter || this.state.filter;
        const query = {
            signal: this.controller?.signal,
        };
        const params = getQueryFilter({ filter });

        let skip = counterScroll ? counterScroll - this.stepCounter : 0;

        if (skip < 0) {
            skip = 0;
        }

        params.push({ key: 'limit', value: this.stepCounter });
        params.push({ key: 'skip', value: skip });

        query.params = params;

        return query;
    }

    getCounterItems(counter) {
        const setInfoHead = this.props.setInfoHead || this.setInfoHead;

        if (setInfoHead) {
            setInfoHead({ key: 'counter', value: counter });
        }
    }

    setItems(items, isFilter, isLimit, counter) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const { items: itemsState = [] } = newState;

                    newState.isLimit = isLimit ?? items.length < this.stepCounter;

                    if (items.length === 0) {
                        newState.isLimit = true;
                    }

                    newState.counter = counter;

                    if (isFilter) {
                        newState.items = items;
                        newState.updateKey = new Date().getTime();
                    } else {
                        items.forEach((item) => {
                            const stateItemIndex = itemsState.findIndex(
                                (stateItem) => stateItem._id === item._id,
                            );

                            if (stateItemIndex === -1) {
                                itemsState.push(item);
                            } else {
                                itemsState[stateItemIndex] = item;
                            }
                        });

                        newState.items = JSON.parse(JSON.stringify(itemsState));
                    }

                    newState.updateListKey = new Date().getTime();

                    newState.saveItems = JSON.parse(JSON.stringify(newState.items || []));

                    return newState;
                },
                () => {
                    this.getCounterItems(counter);

                    removeTransition({
                        item: this.classNameItem || '.tableInfo__row',
                        isCurrent: true,
                    });

                    setTimeout(() => {
                        if (!this.state.isReady) {
                            this.setState({ isReady: true }, () => {
                                if (typeof this.props.setInit === 'function') {
                                    this.props.setInit(true);
                                }
                            });
                        }
                    }, 300);

                    resolve();
                },
            );
        });
    }

    updateItems(isNotRemove = false) {
        if (!this.state.isReady) {
            return Promise.resolve();
        }

        if (this.filterTimerId) {
            clearTimeout(this.filterTimerId);
        }

        return new Promise((resolve) => {
            this.setState({ isLoadingFilter: true }, () => {
                if (this.controller) {
                    this.controller.abort();
                }

                this.controller = new AbortController();

                this.filterTimerId = setTimeout(() => {
                    this.setState(
                        {
                            ...(isNotRemove === true ? {} : { items: [] }),
                            counterScroll: this.stepCounter,
                            isLimit: false,
                            counterUpdate: new Date().getTime(),
                        },
                        () => {
                            this.getItems(null, true).then(
                                () => {
                                    setTimeout(() => {
                                        this.setState(
                                            { isLoadingFilter: false },
                                            this.loadingCallback,
                                        );
                                    }, 100);

                                    resolve();
                                },
                                () => null,
                            );
                        },
                    );
                }, 300);
            });
        });
    }

    updateItem({ id, fields }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items || []));

                const itemIndex = items.findIndex((item) => item._id === id);

                if (itemIndex !== -1) {
                    Object.keys(fields).forEach((key) => {
                        items[itemIndex][key] = fields[key];
                    });
                }

                newState.items = items;
                newState.updatedItemKey = new Date().getTime();

                return newState;
            }, resolve);
        });
    }

    getParentForScroll() {
        return this.parent.current?.querySelector('.tableInfo__scroll');
    }

    handlerUpdateFilter({ filter: filterUpdate, isNotUpdate }) {
        const setFilter = this.props.setFilter || this.setFilter.bind(this);

        return new Promise((resolve) => {
            if (!isNotUpdate) {
                setFilter(filterUpdate);

                this.updateItems().then(resolve, () => null);
            }
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    initFilter({ blocks }) {
        if (this.handlerFilter) {
            this.handlerFilter.init({ blocks });
        }
    }

    setFilter(filter) {
        this.setState({ filter });
    }

    componentDidMount() {
        const { setHeightPage, user } = this.props;

        const initCallbackFilter = this.props.initCallbackFilter || this.initCallbackFilter;

        if (initCallbackFilter) {
            initCallbackFilter(this.handlerUpdateFilter);
        }

        if (this.setHeightPage) {
            this.setHeightPage();
        }

        if (!this.isNotStartGetItems) {
            // setTimeout(() => {
            this.getItems(true);
            // }, 300);
        }

        if (setHeightPage) {
            setHeightPage();
        }

        if (user) {
            this.setState({ user });
        }

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }
}

export default Table;

Table.propTypes = {
    setFilter: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    setHeightPage: PropTypes.func,
    initCallbackFilter: PropTypes.func,
    getFilterModel: PropTypes.func,
    setInit: PropTypes.func,
    user: PropTypes.object,
};
