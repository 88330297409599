import React, { MouseEvent } from 'react';

import changePage from '@functions/changePage.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getPageLink from '@functions/getPageLink.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import CorporationBalanceT from '@global/models/CorporationBalance.ts';

import { RenderColsT } from '../../types.ts';

const renderCorporationBalances: RenderColsT<'corporationBalances'> = function ({
    name: colName,
    item,
}) {
    const balance = item as CorporationBalanceT;
    let content;

    if (colName === 'date') {
        content = (
            <>
                <div className="v2table__text _grey _mediumWeight">
                    {getFormatedDate({
                        date: new Date(balance.createdDate),
                        type: 'full',
                        withSeconds: true,
                    })}
                </div>
            </>
        );
    }

    if (colName === 'description') {
        let description = balance.transactionDescription;

        const match =
            description && Array.from(description.matchAll(/(?:по акту №\*)[\d ]{1,}(?:\*)/gm));

        if (balance.transactionDescription && match && match.length && balance.data?.payId) {
            const [matchItem] = match;
            const matchIndex = matchItem.index;

            description = balance.transactionDescription.slice(0, matchIndex);

            description += `<span class="_link _CLICK">${matchItem[0]}</span>`;
            description += balance.transactionDescription.slice(matchIndex + matchItem[0].length);
        }

        const descriptionHandler = (e: MouseEvent) => {
            const target = e.target as HTMLElement;

            if (target.classList.contains('_link') || target.closest('._link')) {
                const payId = balance.data?.payId as string;

                if (payId) {
                    changePage({
                        href: getPageLink({ name: 'pays-main-inner', ids: { '3': payId } }),
                    });
                }
            }
        };

        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                    onClick={descriptionHandler}
                ></div>
            </>
        );
    }

    if (colName === 'amount') {
        content = (
            <>
                <div
                    className={`v2table__text _mediumWeight ${
                        balance.amount > 0 ? '_successColor' : '_alertColor'
                    }`}
                >
                    {balance.amount > 0 ? '+' : '-'}
                    {getFormatPrice(Math.abs(balance.amount))} ₽
                </div>
            </>
        );
    }

    return { content };
};

export default renderCorporationBalances;
