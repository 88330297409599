import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import checkAuth from '../../../functions/app/checkAuth';
import handlerPopup from '../../../functions/app/handlerPopup';
import checkValueOfEmpty from '../../../functions/checkValueOfEmpty';
import getHeaders from '../../../functions/getHeaders';
import handlerLoading from '../../../functions/handlerLoading';
import { inputValidate } from '../../../functions/inputValidate';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Field from '../../Field.jsx';
import Icon from '../../icon/Icon.tsx';
import Popup from './Popup.jsx';

class WalletPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerField = this.handlerField.bind(this);
        this.save = this.save.bind(this);
        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appWalletPopup', isShow: false });
    }

    fieldsInfo = {
        card: {
            number: {
                keyName: 'numberCard',
            },
        },
        account: {
            number: {
                keyName: 'bankAccount',
            },
        },
    };

    handlerField({ action, name, value, error }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = JSON.parse(JSON.stringify(newState.fields));

                    if (checkValueOfEmpty(value, true)) {
                        fields[name].value = value;
                    }

                    fields[name].error = error || null;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    handlerErrors({ action, errors }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const fields = JSON.parse(JSON.stringify(newState.fields));

                if (!action) {
                    errors.forEach(({ key, error }) => {
                        fields[key].error = error;
                    });
                } else {
                    errors.forEach(({ key }) => {
                        fields[key].error = null;
                    });
                }

                newState.fields = fields;

                return newState;
            }, resolve);
        });
    }

    checkErrors() {
        const { fields, type } = this.state;
        const errors = [];

        Object.keys(fields).forEach((key) => {
            const keyName = this.fieldsInfo[type][key]?.keyName || key;

            if (!inputValidate({ name: keyName, value: fields[key].value })) {
                errors.push(key);
            }
        });

        return { errors, isSuccess: errors.length === 0 };
    }

    checkChangedFields() {
        const { fields, walletId } = this.state;
        const { user } = this.props;
        const { wallets } = user;
        const wallet = wallets.find((item) => item._id === walletId);
        const resultFields = {};

        Object.keys(fields).forEach((key) => {
            if (fields[key].value !== wallet[key]) {
                resultFields[key] = fields[key].value;
            }
        });

        return { fields: resultFields, isChange: Object.keys(resultFields).length > 0 };
    }

    async save() {
        const { walletId } = this.state;
        const { errors, isSuccess } = this.checkErrors();

        if (!isSuccess) {
            this.handlerErrors({ errors: errors.map((key) => ({ key, error: 'error' })) });
        } else {
            const { fields, isChange } = this.checkChangedFields();

            if (isChange) {
                await handlerLoading.call(this, 'save', { error: null });

                const { data } = await axios.patch(
                    `${process.env.REACT_APP_API}/executor-app`,
                    {
                        action: 'change-wallet',
                        walletId,
                        fields,
                    },
                    { headers: getHeaders() },
                );

                try {
                    await handlerLoading.call(this, null);

                    const { success } = data;

                    if (success) {
                        this.hide();

                        checkAuth(false);
                    } else {
                        let error;
                        const message = data.data.message;

                        if (message === 'Bic is incorrect') {
                            error = 'Номер БИК некорректный';
                        } else if (message === 'Card number is incorrect') {
                            error = 'Номер карты некорректный';
                        } else if (message === 'Card number already use') {
                            error = 'Номер карты уже используется';
                        } else if (message === 'Bank account is incorrect') {
                            error = 'Номер счёта некорректный';
                        } else if (message === 'Bank account already use') {
                            error = 'Номер счёта уже используется';
                        }

                        if (data.error?.text) {
                            error = data.error?.text;
                        }

                        if (error) {
                            this.setState({ error });
                        }
                    }
                } catch (error) {
                    await handlerLoading.call(this, null);
                }
            } else {
                this.hide();
            }
        }
    }

    initFields() {
        const { user, appWalletPopup } = this.props;
        const { id } = appWalletPopup;
        const { wallets } = user;
        const wallet = wallets.find((item) => item._id === id);
        const { type } = wallet;

        const fieldsOrder = type === 'card' ? ['number'] : ['bic', 'number', 'addressatName'];

        this.setState((state) => {
            const newState = { ...state };
            const fields = {};

            fieldsOrder.forEach((key) => {
                const value = wallet[key];

                fields[key] = {
                    value,
                    error: null,
                };
            });

            newState.walletId = wallet._id;
            newState.fields = fields;
            newState.type = type;
            newState.isCurrent = user.currentWallet === wallet._id;

            return newState;
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.initFields();
    }

    render() {
        const { fields = {}, loadingKey, type, isCurrent, error } = this.state;

        return (
            <div ref={this.parent} className="appPopup _col _wallet">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__icon">
                                <Icon
                                    name={
                                        type === 'card' ? 'app-wallet-card' : 'app-wallet-account'
                                    }
                                />
                            </div>
                            <div className="appPopup__title">
                                {type === 'card' ? 'Банковская карта' : 'Реквизиты'}
                            </div>
                            {isCurrent && (
                                <div className="appPopup__walletCurrent">
                                    Основной способ оплаты
                                </div>
                            )}

                            <div className="appPopup__fields">
                                {Object.keys(fields).map((key) => {
                                    const field = fields[key];
                                    const keyName = this.fieldsInfo[type]?.[key]?.keyName || key;

                                    return (
                                        <div className="appPopup__field" key={key}>
                                            <Field
                                                className="_showSupport"
                                                type="appWallet"
                                                keyName={keyName}
                                                name={key}
                                                value={field.value}
                                                error={field.error}
                                                callback={this.handlerField}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <AnimateChangeUp
                                className="appPopup__errors _bottom"
                                renderKey={error}
                                parentStyles={['height']}
                            >
                                <div className="appPopup__error">{error}</div>
                            </AnimateChangeUp>
                            <div className="appPopup__button">
                                <Button onClick={this.save} showLoader={loadingKey === 'save'}>
                                    Сохранить изменения
                                </Button>
                            </div>
                            <div className="appPopup__link">
                                <div className="link _click _alert" onClick={this.hide}>
                                    Отменить
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
        user: state.user,
        appWalletPopup: state.appWalletPopup,
    };
}

export default connect(mapStateToProps)(WalletPopup);

WalletPopup.propTypes = {
    windowHeight: PropTypes.number,
    user: PropTypes.object,
    appWalletPopup: PropTypes.object,
};
