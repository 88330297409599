import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import loadPopup from './methods/loadPopup.ts';

import PopupWrapperI from './types.ts';

import popups from './static/popups.ts';

class PopupWrapper
    extends React.Component<PopupWrapperI['props'], PopupWrapperI['state']>
    implements PopupWrapperI
{
    parent: PopupWrapperI['parent'];

    constructor(props: PopupWrapperI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    popups = popups;

    loadPopup = loadPopup;

    render() {
        const { PopupComponent } = this.state;
        const { className = '', isShow, props = {} } = this.props;

        return (
            <Animate
                className={`body__v2popup _POPUPWRAPPER _POPUPBACK ${className}`}
                isShow={isShow}
                actionInit={this.loadPopup.bind(this)}
            >
                <LoaderBlock className="body__v2popupLoader" isShow={!PopupComponent} />
                <div className={`body__v2popupInner ${PopupComponent ? '_show' : ''}`}>
                    <>{PopupComponent ? <PopupComponent {...props} /> : null}</>
                </div>
            </Animate>
        );
    }
}

export default PopupWrapper;
