import I from '../types.ts';

const clickOutHandler: I['clickOutHandler'] = function (this: I, { target }) {
    const thisTarget = target as HTMLElement;

    if (
        thisTarget &&
        thisTarget !== this.parent.current &&
        !this.parent.current!.contains(thisTarget)
    ) {
        // hide();
    }
};

export default clickOutHandler;
