const exportItems = {
    colsOrder: ['name', 'action'],
    cols: {
        name: {
            content: 'Колонка',
            width: 70,
        },
        action: {
            content: 'Экспорт',
            width: 30,
        },
    },
    deletesCols: [],
    emptyInfo: {
        title: 'Подключений пока нет',
        description: 'Вы можете пригласить исполнителя по кнопке справа',
    },
} as const;

export default exportItems;
