import I from '../types.ts';

const init: I['init'] = function () {
    // const { input, startPosition } = this.props;

    // if (input) {
    //     input.focus();
    //     input.selectionEnd = startPosition;
    // }
};

export default init;
