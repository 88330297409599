import React from 'react';

import AppInstruction from '@components/AppInstruction.jsx';
import Icon from '@components/icon/Icon.tsx';

import { BlockT } from '../types.ts';

const Complete: BlockT = function () {
    return (
        <>
            <div className="join__blockInner _col">
                <div className="join__blockDone">
                    <Icon name="success" />
                </div>
                <div className="join__blockTitle">
                    Регистрация
                    <br />
                    пройдена успешно
                </div>
                <div className="join__blockText">
                    Вы успешно прошли регистрацию и&nbsp;можете получать выплаты.
                </div>
                {localStorage.getItem('joinAuthHash') && (
                    <div className="join__blockButton">
                        {this.renderButton({
                            name: 'to-cabinet',
                            text: 'В личный кабинет',
                            onClick: () => {
                                const hash = localStorage.getItem('joinAuthHash');
                                const id = localStorage.getItem('joinAuthId');

                                if (hash && id) {
                                    this.login({ id, hash });
                                }
                            },
                            className: '_notTop',
                        })}
                    </div>
                )}
                <div className="join__instruction _col">
                    <div className="join__instructionLogo">
                        <img
                            src={require('../../../../img/crm/joins/app-logo.svg').default}
                            alt=""
                            className="join__instructionLogoIcon"
                        />
                    </div>

                    <div className="join__instructionTitle">
                        Добавьте наше приложение на главный экран смартфона
                    </div>
                    <AppInstruction isAll={true} />
                </div>
            </div>
        </>
    );
};

export default Complete;
