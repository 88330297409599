import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import Popup from './Popup.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import Icon from '../../icon/Icon.tsx';
import getFormatPrice from '../../../functions/getFormatPrice';
import setSpacesInText from '../../../functions/setSpacesInText';

class TaxAmount extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.hide = this.hide.bind(this);
    }

    hide() {
        super.hide();

        handlerPopup({ name: 'appTaxAmountPopup', isShow: false });
    }

    render() {
        const { amount, description } = this.props;

        return (
            <div ref={this.parent} className="appPopup _col _taxAbout">
                <div className="appPopup__inner">
                    <div className="appPopup__innerBox">
                        <div className="appPopup__content">
                            <div className="appPopup__taxAmount">
                                <div className="appPopup__taxAmountItem _back" />
                                <div className="appPopup__taxAmountItem">
                                    <i className="appPopup__taxAmountItemInfo">
                                        <Icon name="info" />
                                    </i>
                                    <div className="appPopup__taxAmountItemTitle">
                                        {getFormatPrice(amount)} ₽
                                    </div>
                                    <div className="appPopup__taxAmountItemDescription">
                                        {description}
                                    </div>
                                </div>
                            </div>
                            <div className="appPopup__title">Что это за сумма?</div>
                            <p
                                className="appPopup__description"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(
                                        'Это предварительный налог за предыдущий месяц. Мы оплатим эту сумму за вас до 28 числа текущего месяца.',
                                    ),
                                }}
                            ></p>
                            <div className="appPopup__button">
                                <Button onClick={this.hide}>Понятно</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(TaxAmount);

TaxAmount.propTypes = {
    windowHeight: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
};
