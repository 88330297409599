import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import setDateFromStr from '@functions/setDateFromStr.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';

import I from '../types.ts';

const renderDate: I['renderDate'] = function () {
    const {
        model,
        name,
        prop,
        onChange,
        reg,
        concat,
        withTime,
        isDouble,
        withPast,
        setDate,
        disabled,
        cardName,
        inputSupport,
    } = this.props;
    const value = this.props.value as string;

    const startDate = value && isDouble ? value.split('–')[0]?.trim() : null;
    const doubleDate = value && isDouble ? value.split('–')[1]?.trim() : null;
    let date = value ? setDateFromStr(value) : null;

    const updatedKey = `${this.props.updatedKey}${model?.UPDATED_KEY}`;

    if (isDouble) {
        date = startDate ? setDateFromStr(startDate) : null;
    }

    const startDoubleDate = value && isDouble && doubleDate ? setDateFromStr(doubleDate) : null;

    const supportProps =
        !disabled &&
        setCursorSupport(
            {
                _id: `widgetFieldDate-${name}-${cardName}`,
                content: 'Открыть календарь',
                dir: 'top',
            },
            { targetClassName: '.v2widgetField__fieldDate', className: '' },
        );

    return (
        <>
            <div className="v2widgetField__field _date">
                <Input
                    name={name}
                    value={value || ''}
                    onChange={async ({ value: resultValue }) => {
                        onChange({ [prop]: resultValue });
                    }}
                    support={inputSupport || '–'}
                    reg={reg}
                    concat={concat}
                    updatedKey={updatedKey}
                    returnTemplate={true}
                    dateWithPast={!!withPast}
                    disabled={disabled}
                />
                <div
                    className="v2widgetField__fieldDate _COL _CLICK"
                    onClick={() => {
                        if (!disabled) {
                            handlerPopup('calendarPopup', {
                                isShow: true,
                                withTime,
                                isDouble,
                                withPast,
                                startDate: date,
                                startDoubleDate,
                                callback: (data: any) => {
                                    onChange({ [prop]: setDate!(data) }, true);

                                    handlerPopup('calendarPopup', { isShow: false });
                                },
                            });
                        }
                    }}
                    {...supportProps}
                >
                    <i className="v2widgetField__fieldDateIcon">
                        <Icon name="calendar" />
                    </i>
                </div>
            </div>
        </>
    );
};

export default renderDate;
