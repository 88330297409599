import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../icon/Icon.tsx';
import Animate from '../Animate.jsx';
import getFormatedNumber from '../../functions/getFormatedNumber.ts';
import removeTransition from '../../functions/removeTransition.ts';
import HandlerFile from '../../classes/File';

class File extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlay: false,
            currentTime: null,
        };

        this.handlerPlay = this.handlerPlay.bind(this);
        this.checkOtherAudio = this.checkOtherAudio.bind(this);
        this.initAudio = this.initAudio.bind(this);
        this.setProgress = this.setProgress.bind(this);

        this.audio = React.createRef();
        this.parent = React.createRef();
    }

    intervalId = null;

    fileHandler = new HandlerFile({});

    setProgressLine(percent, isForce) {
        const { file } = this.props;
        const progressLine = this.parent.current.querySelector(
            '.chatFile__audioProgressLineActive',
        );
        const progressDot = this.parent.current.querySelector(
            '.chatFile__audioProgressLineActiveDot',
        );

        progressLine.style.width = `${percent * 100}%`;
        progressDot.style.transform = `translate(${100 - percent * 100}%, -50%)`;

        if (isForce) {
            removeTransition({ item: `.chatFile[data-id="${file._id}"] .chatFile__audioProgress` });
        }
    }

    handlerPlay(isPlay = !this.state.isPlay) {
        this.setState({ isPlay, isProccessAudio: true }, () => {
            if (isPlay) {
                this.initAudio();
                this.audio.current.play();

                if (this.state.currentTime === null) {
                    this.setState({ currentTime: 0 });
                }

                this.intervalId = setInterval(() => {
                    const { currentTime, duration } = this.audio.current;
                    const currentTimeInt = Math.floor(currentTime);

                    if (currentTimeInt > this.state.currentTime) {
                        this.setState({ currentTime: currentTimeInt });
                    }

                    if (currentTime <= duration) {
                        this.setProgressLine(currentTime / duration);
                    }
                }, 10);

                document.dispatchEvent(
                    new CustomEvent('audio-play', {
                        detail: { ref: this.audio.current },
                    }),
                );
            } else {
                clearInterval(this.intervalId);
                this.audio.current.pause();
            }
        });
    }

    clearAudio() {
        clearInterval(this.intervalId);
        // this.handlerPlay(false);
        this.setProgressLine(0, true);
        // this.audio.current.currentTime = 0;
        this.setState({ currentTime: null, isProccessAudio: false });
    }

    checkOtherAudio({ detail: { ref } }) {
        const { isProccessAudio } = this.state;

        if (isProccessAudio && ref !== this.audio.current) {
            this.clearAudio();
        }
    }

    initAudio() {
        this.audio.current.addEventListener('pause', () => {
            this.handlerPlay(false);
        });
        this.audio.current.addEventListener('ended', () => {
            this.setProgressLine(1);
            setTimeout(() => {
                this.clearAudio();
            }, 10);
        });
    }

    getCurrentDuration() {
        const { currentTime } = this.state;
        const { file } = this.props;

        if (file.duration) {
            const durationInt = parseInt(currentTime !== null ? currentTime : file.duration, 10);
            const minutes = Math.floor(durationInt / 60);
            const seconds = durationInt - minutes * 60;

            return {
                minutes,
                seconds: getFormatedNumber(seconds),
            };
        }

        return {};
    }

    setProgress(e) {
        if (process.env.REACT_APP_SYSTEM !== 'app') {
            this.setState({ isProccessAudio: true }, () => {
                setTimeout(() => {
                    const progressLine = this.parent.current.querySelector(
                        '.chatFile__audioProgressLine',
                    );
                    const { offsetWidth: widthLine } = progressLine;
                    const leftLine = progressLine.getBoundingClientRect().x;
                    const percent = (e.pageX - leftLine) / widthLine;
                    const { duration } = this.audio.current;

                    this.audio.current.currentTime = duration * percent;

                    this.setProgressLine(percent, true);

                    const currentTimeInt = Math.floor(duration * percent);

                    this.setState({ currentTime: currentTimeInt });
                }, 10);
            });
        }
    }

    componentDidMount() {
        const { file } = this.props;

        if (file.duration) {
            // this.initAudio();
            document.addEventListener('audio-play', this.checkOtherAudio);
        }
    }

    componentWillUnmount() {
        const { file } = this.props;

        if (file.duration) {
            document.removeEventListener('audio-play', this.checkOtherAudio);

            this.clearAudio();
        }
    }

    render() {
        const { isPlay, isProccessAudio } = this.state;
        const { deleteFile, file, className, withOpen } = this.props;
        const { keyFile, type } = file;
        const convertInfo = {};
        const durationInfo = this.getCurrentDuration();

        if (file.size < 0.9 * 1024 * 1024) {
            convertInfo.convertSize = +(file.size / 1024).toFixed(2);
            convertInfo.support = `Кб`;
        } else {
            convertInfo.convertSize = +(file.size / (1024 * 1024)).toFixed(2);
            convertInfo.support = `Мб`;
        }

        const audioProps = { 'webkit-playsinline': true, playsInline: true };

        return (
            <div
                ref={this.parent}
                className={`chatFile _row ${className || ``}`}
                data-id={file._id}
            >
                {deleteFile && (
                    <div
                        className="chatFile__delete _col _click"
                        onClick={() => {
                            if (deleteFile && typeof deleteFile === 'function') {
                                deleteFile({ id: file._id, key: keyFile, keysFile: [keyFile] });
                            }
                        }}
                    >
                        <div className="chatFile__deleteIcon">
                            <Icon name="delete" />
                        </div>
                    </div>
                )}
                {!file.duration && (
                    <div
                        className={`chatFile__preview ${
                            withOpen && file.src ? '_click _link' : ''
                        }`}
                        onClick={(e) => {
                            if (withOpen && file.src) {
                                e.stopPropagation();

                                window.open(file.src, '_blank');
                            }
                        }}
                    >
                        {(file.type === 'image' || this.fileHandler.checkImage(file)) &&
                        (file.src || file.path) ? (
                            <>
                                <img
                                    src={file.src || file.path}
                                    alt=""
                                    className="chatFile__image"
                                />
                            </>
                        ) : (
                            <>
                                <i className="chatFile__logo">
                                    <Icon name="file-type-image" />
                                </i>
                            </>
                        )}
                        <i className="chatFile__icon">
                            <Icon name={`file-type-${type}`} />
                        </i>
                    </div>
                )}

                {file.duration ? (
                    <div className="chatFile__audio _row">
                        {isProccessAudio && (
                            <audio
                                controls
                                src={file.src}
                                type="audio/mpeg"
                                className="chatFile__audioInput"
                                ref={this.audio}
                                {...audioProps}
                            />
                        )}
                        <div
                            className="chatFile__audioButton _click"
                            onClick={() => this.handlerPlay()}
                        >
                            <Animate className="chatFile__audioButtonIcon _pause" isShow={isPlay}>
                                <Icon name="audio-pause" />
                            </Animate>
                            <Animate className="chatFile__audioButtonIcon _play" isShow={!isPlay}>
                                <Icon name="audio-play" />
                            </Animate>
                        </div>
                        <div className="chatFile__audioProgress">
                            <div
                                className="chatFile__audioProgressLine _click"
                                onClick={this.setProgress}
                            >
                                <div className="chatFile__audioProgressLineActive">
                                    <div className="chatFile__audioProgressLineActiveDot"></div>
                                </div>
                            </div>
                            <div className="chatFile__audioProgressDuration">
                                {durationInfo.minutes}:{durationInfo.seconds}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="chatFile__info _col">
                        <p className="chatFile__name">{file.name}</p>
                        <p className="chatFile__size">
                            {convertInfo.convertSize}
                            {convertInfo.support}
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(File);

File.propTypes = {
    className: PropTypes.string,
    file: PropTypes.object,
    deleteFile: PropTypes.func,
    withOpen: PropTypes.bool,
};
