import I from '../types.ts';

const getMoreItems: I['getMoreItems'] = function (counter) {
    return new Promise((resolve) => {
        this.setState({ counterScroll: counter }, () => {
            resolve();
        });
    });
};

export default getMoreItems;
