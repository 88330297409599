import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon/Icon.tsx';

class Back extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { isShow = true, children, handler } = this.props;

        return (
            <div
                className={`back _row _click ${isShow ? '_show' : ''}`}
                onClick={() => {
                    if (isShow) {
                        handler();
                    }
                }}
            >
                <i className="back__icon">
                    <Icon name="arrow-prev" />
                </i>
                {children || 'Назад'}
            </div>
        );
    }
}

export default Back;

Back.propTypes = {
    isShow: PropTypes.bool,
    children: PropTypes.node,
    handler: PropTypes.func,
};
