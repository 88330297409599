import React from 'react';

import CursorEmodzi from '@components/cursorEmodzi/CursorEmodzi.tsx';

import RootI from '../types.ts';

const renderCursorEmodziLists: RootI['renderCursorEmodziLists'] = function () {
    return (
        <>
            <CursorEmodzi />
        </>
    );
};

export default renderCursorEmodziLists;
