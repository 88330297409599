import React from 'react';

import Animate from '@components/animate/Animate';
import AnimateChange from '@components/animateChange/AnimateChange';
import MailingProgressCircle from '@components/crm/manual/MailingProgressCircle';
import ManualTableI from '@components/crm/table/types';
import Icon from '@components/icon/Icon';
import Loader from '@components/loader/Loader';
import getEndText from '@functions/getEndText';
import ChatMailingT from '@global/models/ChatMailing';

import MailingsI from '../../../../../../views/crm/mailings/types';

type ParamsT = {
    mailing: ChatMailingT;
    mailingHandler: MailingsI['mailingHandler'];
    loadingHandler: ManualTableI['loadingHandler'];
    loadingKey: ManualTableI['state']['loadingKey'];
};

export default function Actions({
    mailing,
    mailingHandler,
    loadingHandler,
    loadingKey,
}: ParamsT): React.ReactNode {
    let status = 'wait';
    let title = 'Запустить рассылку';
    let description = `${mailing.allCounter} ${getEndText(mailing.allCounter, ['исполнитель', 'исполнителя', 'исполнителей'])}`;

    if (mailing.isComplete) {
        title = 'Отправлено';
        description = `${mailing.allCounter} ${getEndText(mailing.allCounter, ['исполнитель', 'исполнителя', 'исполнителей'])}`;
        status = 'completed';
    } else if (mailing.inPause) {
        title = 'Продолжить';
        description = `Отправлено: ${mailing.completedCounter} из ${mailing.allCounter}`;
        status = 'pause';
    } else if (mailing.inProcess) {
        title = 'Прервать рассылку';
        description = `Отправлено: ${mailing.completedCounter} из ${mailing.allCounter}`;
        status = 'process';
    }

    const progress = mailing.completedCounter / mailing.allCounter;

    return (
        <>
            <div
                className={`v2table__mailing ${!mailing.isComplete ? '_click' : ''} _${status}`}
                onClick={async (e) => {
                    if (!mailing.isComplete) {
                        e.stopPropagation();

                        if (!loadingKey) {
                            const action =
                                !mailing.inProcess || mailing.inPause ? 'start' : 'pause';

                            await loadingHandler({ name: mailing._id, loading: true });

                            try {
                                await mailingHandler({ id: mailing._id, action });
                            } catch (error) {}

                            await loadingHandler({ name: mailing._id, loading: undefined });
                        }
                    }
                }}
            >
                <div className="v2table__mailingTitle">
                    <AnimateChange
                        className="v2table__mailingTitleInner"
                        renderKey={status}
                        styles={['height']}
                    >
                        {title}
                    </AnimateChange>
                    <div
                        className={`v2table__mailingBtn _col ${
                            loadingKey ? '_loading' : ''
                        } _${status}`}
                    >
                        <Animate className="v2table__mailingBtnLoader" isShow={loadingKey}>
                            <Loader className="_main" />
                        </Animate>
                        <Animate
                            className="v2table__mailingBtnProgress"
                            isShow={status === 'process' || status === 'pause'}
                        >
                            <MailingProgressCircle progress={progress} />
                        </Animate>
                        <Animate
                            className="v2table__mailingBtnIcon"
                            isShow={status === 'pause' || status === 'wait'}
                        >
                            <Icon name="mailing-play" />
                        </Animate>
                        <Animate className="v2table__mailingBtnIcon" isShow={status === 'process'}>
                            <Icon name="mailing-pause" />
                        </Animate>
                        <Animate
                            className="v2table__mailingBtnIcon"
                            isShow={status === 'completed'}
                        >
                            <Icon name="mailing-completed" />
                        </Animate>
                    </div>
                </div>
                <AnimateChange
                    className="v2table__mailingDescription"
                    renderKey={status}
                    styles={['height']}
                >
                    {description}
                </AnimateChange>
                <div className="v2table__mailingProgress">
                    <div
                        className="v2table__mailingProgressLine"
                        style={{
                            width: `${progress * 100}%`,
                        }}
                    />
                </div>
            </div>
        </>
    );
}
